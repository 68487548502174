import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';

class Component extends BaseComponent {
    shouldComponentUpdate(nextProps) {
        if (nextProps.logged_in != this.props.logged_in) {
            return true;
        }

        return false;
    }

    render({ logged_in }) {
        if (!logged_in) {
            return (
                <ul className='user-menu-lo'>
                    <li>
                        <a
                            href='#login-signup'
                            className='button'
                            data-label='Sign Up'
                            data-info='SignUp'
                            onClick={this.trackClick}
                        >
                            Sign Up!
                        </a>
                    </li>
                    <p>OR</p>
                    <li>
                        <a
                            href='#login'
                            data-label='Log In'
                            data-info='LogIn'
                            onClick={this.trackClick}
                        >
                            Log In
                        </a>
                    </li>
                </ul>
            );
        }

        return null;
    }

    trackClick = (e) => {
        e.preventDefault();
        this.props.trackEvent([
            'Click',
            `Nav_Main:${e.currentTarget.dataset.info}_${this.props.track_loli}`,
        ]);
        this.props.trackPromo([
            'Navigation',
            e.currentTarget.dataset.label.replace(/\s+/g, ''),
        ]);
        this.props.routeTo(e.currentTarget.hash.replace('#', ''));
    };
}

const mapStateToProps = (state) => ({
    logged_in: state.user && state.user.logged_in,
    track_loli: state.user && state.user.logged_in ? 'LI' : 'LO',
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    trackPromo: (payload) => dispatch({ type: 'promo-tracker:recordInternal', payload: payload }),
    routeTo: (payload) => dispatch({ type: 'route:to', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
