import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import CompatibilityChooser from './chooser';

class Component extends BaseComponent {
    constructor() {
        super();
        this.state = { isOpen: false };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.isOpen != this.state.isOpen) {
            return true;
        }

        return false;
    }

    render({ title, short_description, slant, type }, { isOpen }) {
        return (
            <astrology-compatibility>
                <h1>{title}</h1>
                <p>
                    <span className='short-description' dangerouslySetInnerHTML={{ __html: short_description }} />
                    <a href='#' onClick={this.toggleDetails}>
                        {isOpen ? 'Show Less' : 'Learn More'}
                    </a>
                </p>
                {this.renderDetails()}
                <span>
                    <CompatibilityChooser slant={slant} type={type} />
                </span>
            </astrology-compatibility>
        );
    }

    toggleDetails = (e) => {
        e.preventDefault();
        this.setState({ isOpen: !this.state.isOpen });
    }

    renderDetails = () => {
        if (this.state.isOpen) {
            return (
                <p className='learn-more' dangerouslySetInnerHTML={{ __html: this.props.description }} />
            );
        }

        return null;
    }
}

const mapStateToProps = (state) => ({
    title: state.compatibility && state.compatibility.title,
    description: state.compatibility && state.compatibility.description,
    short_description: state.compatibility && state.compatibility.meta && state.compatibility.meta.description,
});

export default connect(mapStateToProps)(Component);
