import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import _ from 'underscore';
import { date_to_sign_name, signs } from '../../shared/util';

dayjs.extend(customParseFormat);

class Component extends BaseComponent {
    constructor(props) {
        super(props);
        const months = _.range(12);
        const days = _.range(31);

        this.state = {
            month: null,
            months: months,
            day: null,
            days: days,
            sign: null,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.month && nextState.day && this.props.base_url) {
            const date = `${nextState.month}-${nextState.day}`;

            this.props.trackEvent([
                'Click',
                'Nav_TopPink_BDAY:DatePicker',
            ]);

            // year doesn't matter for date_to_sign_name, but it must be a valid date for firefox
            document.location.href = `${this.props.base_url}/${date_to_sign_name(`2000-${date}`)}/${date}`;
        }

        if (nextState.sign && this.props.base_url) {
            this.props.trackEvent([
                'Click',
                `Nav_TopPink_BDAY:${nextState.sign}`,
            ]);

            document.location.href =
                `${this.props.base_url}/${nextState.sign}/${this.sign_name_to_dates(nextState.sign, 'MM-DD')[0]}`;
        }

        if (nextState.month != this.state.month) {
            return true;
        }

        if (nextState.day != this.state.day) {
            return true;
        }

        return false;
    }

    /* eslint-disable max-len */
    render({ base_url }) {
        return (
            <birthday-select base_url={base_url}>
                <label>{this.renderMonths()}</label>
                <label>{this.renderDays()}</label>
                <span
                    className='is-hidden-desktop is-hidden-tablet-landscape has-text-size-12 has-text-eminence has-margin-5'
                >
                    OR
                </span>
                <label>{this.renderSigns()}</label>
            </birthday-select>
        );
    }
    /* eslint-enable max-len */

    renderMonths = () => {
        return (
            <select
                onChange={this.setMonth}
                name='month'
            >
                <option selected disabled>month</option>
                {this.state.months.map(month => (
                    <option value={dayjs().month(month).format('MM')}>
                        {dayjs().month(month).format('MMMM')}
                    </option>
                ))}
            </select>
        );
    }

    setMonth = (e) => {
        const days = _.range(dayjs(`2012-${e.target.value}`, 'YYYY-MM').daysInMonth());

        this.setState({
            month: e.target.value,
            day: this.state.day,
            days: days,
        });

    }

    sign_name_to_dates = (sign, format = 'MM-DD') => {
        const signIndex = signs.findIndex(signObj => signObj.name === sign.toLowerCase());

        if (signIndex === -1) {
            return null;
        }
        const dates = signs[signIndex];

        return [dayjs(dates.start, 'MMM DD').format(format), dayjs(dates.end, 'MMM DD').format(format)];
    };

    renderDays = () => {
        return (
            <select
                onChange={this.setDay}
                name='day'
            >
                <option selected disabled>day</option>
                {this.state.days.map(day => (
                    <option value={('0' + (day + 1)).substr(-2)}>{day + 1}</option>
                ))}
            </select>
        );
    }

    setDay = (e) => {
        this.setState({ day: e.target.value });
    }

    renderSigns = () => {
        const signs = this.props.signs.map(sign => sign.name);

        return (
            <select
                onChange={this.setSign}
                name='sign'
                className='is-hidden-desktop is-hidden-tablet-landscape'
            >
                <option selected disabled>Choose Sign</option>
                {signs.map(sign => (
                    <option value={sign}>{sign}</option>
                ))}
            </select>
        );
    }

    setSign = (e) => {
        this.setState({ sign: e.target.value });
    }
}

const mapStateToProps = (state) => ({
    signs: state.zodiac && state.zodiac.signs,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
