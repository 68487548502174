import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import NavArticles from './articles';

class Component extends BaseComponent {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.logged_in != this.props.logged_in) {
            return true;
        }

        if (nextState.width >= 900 && this.state.width < 900) {
            return true;
        }

        if (nextState.width < 900 && this.state.width >= 900) {
            return true;
        }

        return false;
    }

    render({ navkey, navigation }) {
        if (navigation[navkey] && navigation[navkey].submenu) {
            return (
                <nav-submenu navkey={navkey}>
                    {navigation[navkey].submenu.map(subitem => this.renderSubmenu(navigation[navkey], subitem))}
                    {this.renderNavArticles(navigation[navkey])}
                </nav-submenu>
            );
        }

        return (<nav-submenu navkey={navkey} />);
    }

    renderSubmenus = (item) => {
        if (item.submenu) {
            return (
                <div className='submenu'>
                    {item.submenu.map(subitem => this.renderSubmenu(item, subitem))}
                    {this.renderNavArticles(item)}
                </div>
            );
        }

        return null;
    }

    renderSubmenu = (item, subitem) => {
        if (item.name == 'Psychics') {
            return (
                <div className='submenu-category nav-full'>
                    <promo-message placement='psychic-nav-links' />
                </div>
            );
        }

        const href = this.props.logged_in && subitem.logged_in ? subitem.logged_in.link : subitem.link;
        const name = this.props.logged_in && subitem.logged_in ? subitem.logged_in.name : subitem.name;

        return (
            <div className='submenu-category nav-full'>
                <a
                    href={href}
                    target={subitem.target || ''}
                    rel={subitem.rel || ''}
                    onClick={() => this.trackClick(`${item.name} - ${name}`,
                        `Nav_Main:${item.name}_${this.props.track_loli}:${name}`)}
                >
                    {name}
                </a>
                <ul>
                    {subitem.links.map(destination => this.renderSubmenuList(item, destination))}
                </ul>
            </div>
        );
    }

    renderSubmenuList = (item, destination) => {
        if (destination.logged_in && this.props.logged_in) {
            return (
                <li>
                    <a
                        href={destination.logged_in.link}
                        target={destination.target || ''}
                        rel={destination.rel || ''}
                        className='nav-link'
                        onClick={() => this.trackClick(
                            `${item.name} - ${destination.logged_in.name}`,
                            `Nav_Main:${item.name}_${this.props.track_loli}:${destination.logged_in.name}`
                        )}
                    >
                        {destination.logged_in.name}
                    </a>
                </li>
            );
        }

        return (
            <li>
                <a
                    href={destination.link}
                    target={destination.target || ''}
                    rel={destination.rel || ''}
                    className='nav-link'
                    onClick={() => this.trackClick(
                        `${item.name} - ${destination.name}`,
                        `Nav_Main:${item.name}_${this.props.track_loli}:${destination.name}`
                    )}
                >
                    {destination.name}
                </a>
            </li>
        );
    }

    renderNavArticles = (item) => {
        return (
            <NavArticles
                tag={item.articles && item.articles.tag}
                category={item.name}
            />
        );
    }

    trackClick = (label, info) => {
        this.props.trackEvent(['Click', info]);
        this.props.trackPromo(['Navigation', label]);

        return true;
    }

    updateClickState = (e, label) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ recentClick: label });
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.user && state.user.logged_in,
    navigation: state.navigation,
    track_loli: state.user && state.user.logged_in ? 'LI' : 'LO',
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    trackPromo: (payload) => dispatch({ type: 'promo-tracker:recordInternal', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);