import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

class Component extends BaseComponent {
    constructor(props) {
        super(props);

        const show = props.shown || props.hide_policy_consent ? false : true;

        this.state = { show: show };

        if (props.ppc) {
            props.setCookie({
                name: 'POLICY_CONSENT_SHOWN',
                value: 1,
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.ppc != this.props.ppc) {
            return true;
        }

        if (nextState.show != this.state.show) {
            return true;
        }

        if (nextProps.logged_in != this.props.logged_in) {
            if (Cookies.get('PRIVACY_POLICY_CONSENT')) {
                this.setState({ show: false });
            }

            return true;
        }

        return false;
    }

    /* eslint-disable max-len */
    render({ ppc }, { show }) {
        if (show && !ppc) {
            return (
                <policy-consent className='shown'>
                    <p>
                        We use cookies to improve your browsing experience, analyze our traffic, and offer
                        personalized content and advertising. By continuing to use this site, you agree to the usage
                        of cookies as outlined <a href='/cookie-policy' target='_blank' rel='noopener'>here</a>, as well as our
                        updated <a href='/privacy-policy' target='_blank' rel='noopener'>Privacy Policy</a> and&nbsp;
                        <a href='/terms-of-use' target='_blank' rel='noopener'>Terms of Use</a>.
                    </p>
                    <a className='close' onClick={this.decline}>×</a>
                    <a className='button' onClick={this.accept}>I Accept</a>
                </policy-consent>
            );
        }

        return (<policy-consent />);
    }

    /* eslint-enable max-len */
    accept = () => {
        this.props.trackEvent([
            'Click',
            'Modal_PolicyConsent:Accept',
        ]);

        this.props.updateMemberPrivacyPolicy();

        this.props.setCookie({
            name: 'PRIVACY_POLICY_CONSENT',
            value: 1,
        });

        this.props.setCookie({
            name: 'POLICY_CONSENT_SHOWN',
            value: 1,
        });

        this.setState({ show: false });
    }

    decline = () => {
        this.props.trackEvent([
            'Click',
            'Modal_PolicyConsent:DeclineClose',
        ]);

        this.props.setCookie({
            name: 'POLICY_CONSENT_SHOWN',
            value: 1,
            opts: {
                expires: dayjs().add(24, 'hour').toDate(),
            },
        });

        this.setState({ show: false });
    }
}

const mapStateToProps = (state) => ({
    ppc: state.cookie && state.cookie.PRIVACY_POLICY_CONSENT,
    logged_in: state.user && state.user.logged_in,
    shown: state.cookie && state.cookie.POLICY_CONSENT_SHOWN,
    hide_policy_consent: state.hide_policy_consent,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({
        type: 'ga:event',
        payload: payload,
    }),
    setCookie: (payload) => dispatch({
        type: 'cookie:set',
        payload: payload,
    }),
    updateMemberPrivacyPolicy: () => dispatch({
        type: 'member:privacy-policy-consent',
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
