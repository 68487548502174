import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { smoothScrollToElement } from '../../shared/scroll';

class Component extends BaseComponent {
    constructor(props) {
        super(props);
        const attrs = { ...props };
        delete attrs.children;
        delete attrs.innerHTML;
        delete attrs.routeTo;

        this.state = { attrs };
    }

    shouldComponentUpdate() {
        return false;
    }

    render({ innerHTML }, { attrs }) {
        return (
            <a
                {...attrs}
                dangerouslySetInnerHTML={{ __html: innerHTML || '' }}
                onClick={this.click}
            />
        );
    }

    click = (e) => {
        const el = document.querySelector(this.state.attrs.href.replace(/^[^#]*/, ''));

        if (el) {
            e.preventDefault();
            smoothScrollToElement(el);

            this.props.routeTo(this.state.attrs.href.replace(/^#/, ''));

            return false;
        }

        return true;
    }
}

const mapDispatchToProps = (dispatch) => ({
    routeTo: (hash) => dispatch({ type: 'route:to', payload: hash }),
});

export default connect(null, mapDispatchToProps)(Component);
