import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { isValidEmail } from '../../../shared/util';
import { Suspense, lazy } from 'preact/compat';
const InputGroup = lazy(
    () => import(/* webpackChunkName: "input-group" */ '../../../shared/form/input-group')
);

class Component extends BaseComponent {
    constructor(props) {
        super(props);

        if (props.user && props.user.logged_in) {
            this.state = {
                email: {
                    value: props.user && props.user.email,
                    error: null,
                },
                password: {
                    value: null,
                    error: null,
                },
            };
        }
    }

    componentWillUnmount() {
        this.props.userUpdate({
            passwordError: null,
        });
    }

    render({ passwordError, user, close }, { email, password }) {
        if (user && user.logged_in) {
            return (
                <password-modal>
                    <dialog-header>
                        <button onClick={close}>×</button>
                        <h1 className='fancy-title has-text-center'>
                            Welcome back, {user.username}
                        </h1>
                        <p className='has-text-center has-margin-0-bottom'>
                            Please enter your password to continue:
                        </p>
                    </dialog-header>
                    <dialog-content>
                        <form autoComplete='on' onSubmit={this.validate}>
                            <row>
                                <column className='is-12'>
                                    <Suspense fallback={<div className='skeleton' />}>
                                        <InputGroup
                                            disabled
                                            type='text'
                                            name='email'
                                            label='Your Email'
                                            value={email.value}
                                        />
                                    </Suspense>
                                </column>
                            </row>
                            <row>
                                <column className='is-12'>
                                    <Suspense fallback={<div className='skeleton' />}>
                                        <InputGroup
                                            autoFocus
                                            type='password'
                                            name='password'
                                            label='Password'
                                            value={password.value}
                                            error={password.error || passwordError}
                                            onInput={this.setFormValues}
                                        />
                                    </Suspense>
                                </column>
                            </row>
                            <row className='is-aligned-center'>
                                <column className='is-3 has-text-center'>
                                    <a
                                        href='/password-reset'
                                        target='_blank'
                                        onClick={() => this.trackClick(
                                            'Modal_AutoLogIn:AccountSettings-ForgotYourPassword'
                                        )}
                                        className='forgot has-text-size-11 has-line-height-14'
                                    >
                                        Forgot Your Password?
                                    </a>
                                </column>
                                <column className='is-6 has-text-center'>
                                    <button onClick={this.validate}>ENTER</button>
                                </column>
                                <column className='is-3 has-text-center'>
                                    <p className='has-margin-0-bottom has-text-size-11 has-line-height-14'>Not You?</p>
                                    <a
                                        href='#'
                                        onClick={this.logoutHomeLogin}
                                        className='has-text-size-11 has-line-height-14'
                                    >
                                        Click here to log in
                                    </a>
                                </column>
                            </row>
                        </form>
                    </dialog-content>
                </password-modal>
            );
        }

        return null;
    }

    setFormValues = (e) => {
        let state = {};

        if ((e.type == 'keyup' && e.key == 'Enter') || e.type == 'change' || e.type == 'input') {
            state[e.target.name] = {
                value: e.target.value,
                error: null,
            };

            this.setState(state);
        }
    }

    validate = (e) => {
        e.preventDefault();
        this.trackClick('Modal_AutoLogIn:AccountSettings-EnterPW');

        this.props.userUpdate({
            passwordError: null,
        });

        const state = {
            email: {
                value: this.state.email.value,
                error: isValidEmail(this.state.email.value) ? null : 'Please enter a valid email',
            },
            password: {
                value: this.state.password.value,
                error: this.state.password.value ? this.validatePassword() : 'Please enter a password',
            },
        };

        this.setState(state);

        if (
            !state.email.error
            &&
            !state.password.error
        ) {
            this.props.userLoginViaPassword({
                email: this.state.email.value,
                password: this.state.password.value,
            });
        }
    }

    validatePassword = () => {
        return this.state.password.value.length > 3 ? null : 'Please enter a password longer than 3 characters.';
    }

    trackClick = (info) => {
        this.props.trackEvent(['Click', info]);
    }

    logoutHomeLogin = (e) => {
        e.preventDefault();
        this.trackClick('Modal_AutoLogIn:AccountSettings-NotYou');
        this.props.userLogoutHomeLogin();
    }
}

const mapStateToProps = (state) => ({
    route: state.route,
    product: state.product,
    user: state.user,
    mailListType: state['mail-lists'] && state['mail-lists'].type,
    passwordError: state.user && typeof state.user.passwordError === 'boolean'
        ? 'Password did not match this email'
        : state.user.passwordError,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    userLoginViaPassword: (payload) => dispatch({ type: 'user:login-via-password', payload: payload }),
    userUpdate: (payload) => dispatch({ type: 'user:update', payload: payload }),
    userLogoutHomeLogin: () => dispatch({ type: 'user:logout-home-login' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
