import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { MediaQuery as MQ } from '../../shared/media-query';

class Component extends BaseComponent {
    shouldComponentUpdate() {
        return false;
    }

    render({ mode, base_url, title, general_link, category, page_name, footer_link }) {
        return (
            <zodiac-sign-picker
                mode={mode}
                base_url={base_url}
                title={title}
                general_link={general_link}
                category={category}
                page_name={page_name}
                footer_link={footer_link}
            >
                <row className='is-wrap'>
                    {this.renderTitle()}
                    {this.renderGeneralLink()}
                </row>
                {this.renderTiles()}
                <row>
                    <column className='is-12'>
                        {this.renderFooterLink()}
                    </column>
                </row>
            </zodiac-sign-picker>
        );
    }

    renderTitle = () => {
        if (this.props.title) {
            const className = this.props.general_link && this.props.general_link !== 'false'
                ? 'is-8 is-12-mobile'
                : 'is-12';

            return (
                <column className={className}>
                    <h2 className='is-h4'>{this.props.title}</h2>
                </column>
            );
        }

        return (<p>Choose A Sign:</p>);
    }

    renderGeneralLink = () => {
        if (this.props.general_link && this.props.general_link !== 'false' && !MQ.isMobile()) {
            const label = this.props.category ?
                this.props.page_name + ':Zodiac Picker:General' : 'General';
            return (
                <a
                    href={this.renderUrl('general')}
                    className='header'
                    onClick={() => this.trackClick(label, 'General')}
                >
                    GENERAL HOROSCOPE
                </a>
            );
        }

        return null;
    }

    renderTiles = () => {
        if (this.props.mode == 'tiles' || this.state.isOpen) {
            return (
                <div className='zodiac-signs'>
                    {this.props.signs.map(sign => {
                        let sign_name = this.capitalize(sign.name);
                        let label = this.props.category ?
                            this.props.page_name + ':Zodiac Picker:' + sign_name : sign_name;

                        return (
                            <a
                                className={`zodiac-sign ${sign.name}`}
                                href={this.renderUrl(sign.name)}
                                onClick={() => this.trackClick(label, sign_name)}
                            >
                                <p className='sign'>{sign.name}</p>
                                <p className='dates'>{sign.start} - {sign.end}</p>
                            </a>
                        );
                    })}
                </div>
            );
        }

        return null;
    }

    trackClick = (label, sign) => {
        const category = this.props.category ? this.props.category : 'Zodiac Sign Picker';

        this.props.trackEvent(['Click', `ZodiacPicker:${sign}`]);
        if (category == 'Dropdown') {
            this.props.trackPromo([category, label]);
        }
    }

    renderUrl = (sign) => {
        let url = this.props.base_url;

        if (this.props.base_url == '#') {
            url = url + sign;
        } else {
            url = `${url}/${sign}`;
        }

        return url;
    }

    renderFooterLink = () => {
        if (
            this.props.footer_link
            ||
            (
                this.props.general_link
                &&
                this.props.general_link !== 'false' && MQ.isMobile()
            )
        ) {
            const label = this.props.category ?
                this.props.page_name + ':Zodiac Picker:General' : 'General';
            return (
                <a
                    href={this.renderUrl('general')}
                    className='footer'
                    onClick={() => this.trackClick(label, 'General')}
                >
                    GENERAL HOROSCOPE
                </a>
            );
        }

        return null;
    }

    capitalize = (str) => {
        return str[0].toUpperCase() + str.slice(1);
    }
}

const mapStateToProps = (state) => ({
    signs: state.zodiac && state.zodiac.signs,
});


const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    trackPromo: (payload) => dispatch({ type: 'promo-tracker:recordInternal', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
