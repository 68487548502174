import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { MediaQuery as MQ } from '../../../shared/media-query';
import { hasTouchEvents } from '../../../shared/util';
import { toggleDOMClass } from '../../../shared/util';

class Component extends BaseComponent {
    constructor() {
        super();
        this.state = { width: window.innerWidth };
        this.showNavElements = 'body, .ad-topbanner, main, footer, top-bar, article-collection, mobile-action-bar';
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.width >= 900 && this.state.width < 900) {
            this.toggle(true);
            return true;
        }

        if (nextState.width < 900 && this.state.width >= 900) {
            return true;
        }

        if (nextProps.logged_in != this.props.logged_in) {
            this.closeNav();
            return true;
        }

        return false;
    }

    render({ noads, class: className }) {
        if (MQ.isMobile() || MQ.isTabletPortrait() || (hasTouchEvents && MQ.isPortrait())) {
            return (
                <mobile-menu noads={noads} className={className}>
                    <a className='toggle-menu' onClick={() => this.toggle(false)} ref={(el) => this.toggleMenu = el}>
                        <div className='icon'>
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                    </a>
                </mobile-menu>
            );
        }

        return (<mobile-menu noads={noads} className={className} />);
    }

    toggle = (resize = false) => {
        const isOpen = this.toggleMenu && this.toggleMenu.classList && this.toggleMenu.classList.contains('open');

        if (window.innerWidth >= 900) {
            this.closeNav();
        } else if (!isOpen) {
            if (this.toggleMenu.classList) {
                this.toggleMenu.classList.add('open');
            }

            // From: https://web.dev/articles/optimize-inp#yield_to_allow_rendering_work_to_occur_sooner
            // Use `setTimeout` to defer all other work until at least the next
            // frame by queuing a task in a `requestAnimationFrame()` callback.
            requestAnimationFrame(() => {
                setTimeout(() => {
                    window.hideSMVideoUnit();
                    toggleDOMClass(this.showNavElements, 'show-nav', 'add');
                    this.props.showNavUpdate({ show_nav: true });
                }, 0);
            });
        } else {
            this.closeNav();
        }

        requestAnimationFrame(() => {
            setTimeout(() => {
                const adAdhesion = document.getElementById('pmc-atlasmg-adhesion-bar');

                if (!resize) {
                    this.props.trackEvent(['Click', 'SiteInteraction:MobileNav']);
                }

                adAdhesion && (adAdhesion.style.zIndex = isOpen ? '5999998' : '0');
            }, 0);
        });
    }

    closeNav = () => {
        if (this.toggleMenu && this.toggleMenu.classList) {
            this.toggleMenu.classList.remove('open');
        }

        requestAnimationFrame(() => {
            setTimeout(() => {
                window.showSMVideoUnit();
                toggleDOMClass(this.showNavElements, 'show-nav', 'remove');
                this.props.showNavUpdate({ show_nav: false });
            }, 0);
        });
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.user && state.user.logged_in,
    show_nav: state.ui && state.ui.show_nav,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    showNavUpdate: (payload) => dispatch({ type: 'ui:update', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
