import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';

class Component extends BaseComponent {
    constructor(props) {
        super(props);

        this.innerHTML = props.innerHTML;
        delete(props.innerHTML);

        if (props.mode == 'keep' && !props.logged_in) {
            this.state = { show: true };
        } else {
            this.state = { show: false };
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.show != this.state.show) {
            return true;
        }

        if (nextProps.logged_in != this.props.logged_in) {
            return true;
        }

        return false;
    }

    render({ innerHTML, mode, logged_in }, { show }) {
        if (innerHTML) {
            delete(this.props.innerHTML); //eslint-disable-line tarot/no-direct-props-access-in-render
        }

        if (!logged_in || show) {
            return (
                <logged-out mode={mode} className='shown'>
                    <span dangerouslySetInnerHTML={{ __html: this.innerHTML }} />
                </logged-out>
            );
        }

        return (<logged-out {...this.props} />); //eslint-disable-line tarot/no-direct-props-access-in-render
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.user && state.user.logged_in,
});

export default connect(mapStateToProps)(Component);
