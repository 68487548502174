import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';

export class Component extends BaseComponent {
    shouldComponentUpdate(nextProps) {
        if (nextProps.price != this.props.price) {
            return true;
        }

        return false;
    }

    render() {
        return (
            <sale-message>
                {this.renderMessage()}
            </sale-message>
        );
    }

    renderMessage = () => {
        if (this.props.price) {
            if (this.props.price.usd && this.props.price.usd.message) {
                return (<p className='sale-msg' dangerouslySetInnerHTML={{ __html: this.props.price.usd.message }} />);
            } else if (this.props.price.kc && this.props.price.kc.message) {
                return (<p className='sale-msg' dangerouslySetInnerHTML={{ __html: this.props.price.kc.message }} />);
            }
        }

        return null;
    }
}

const mapStateToProps = (state) => ({
    price: state.product.price,
});

export default connect(mapStateToProps)(Component);
