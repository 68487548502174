import { Component as BaseComponent, h } from 'preact';
import { Suspense, lazy } from 'preact/compat';
import { connect } from 'react-redux';
const PanelContent = lazy(
    () => import(/* webpackChunkName: "panel-content" */ '../../dynamic-components/panel-content/index')
);

class Component extends BaseComponent {
    shouldComponentUpdate(nextProps) {
        if (nextProps.logged_in != this.props.logged_in) {
            return true;
        }

        if (nextProps['data-panel'] != this.props['data-panel']) {
            this.el && (this.el.scrollTop = 0);

            return true;
        }

        return false;
    }

    render({ 'data-panel': dataPanel, class: className }) {
        if (dataPanel && dataPanel != '') {
            return (
                <panel-loader className={className} data-panel={dataPanel} ref={el => this.el = el}>
                    <Suspense fallback={<div className='skeleton' />}>
                        <PanelContent panel={dataPanel} />
                    </Suspense>
                </panel-loader>
            );
        }

        return (<panel-loader className={className} data-panel={dataPanel} ref={el => this.el = el} />);
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.user && state.user.logged_in,
});

export default connect(mapStateToProps)(Component);
