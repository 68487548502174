import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import _ from 'underscore';

class Component extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            showPathDropdown: false,
            showHashDropdown: false,
        };

        let path = null;

        if (props.sign_one && props.sign_one != 'general' ) {
            path = _.findWhere(props.signs, { name: props.sign_one });
            props.set({ path: path });
        } else if (
            props.route
            && props.signs
            && props.signs.map(sign => sign.name).includes(props.route)
        ) {
            path = _.findWhere(props.signs, { name: props.route });
            props.set({ path: path });
        }

        props.set({
            type: 'zodiac',
            slant: props.slant,
            base_url: '/astrology/compatibility/' + props.slant + '/',
            path: path,
            hash: null,
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.path != this.props.path) {
            return true;
        }

        if (nextProps.hash != this.props.hash) {
            return true;
        }

        if (nextState.showHashDropdown != this.state.showHashDropdown) {
            return true;
        }

        if (nextState.showPathDropdown != this.state.showPathDropdown) {
            return true;
        }

        return false;
    }

    render({ path, hash, sign_one, signs }, { showPathDropdown, showHashDropdown }) {
        if (!sign_one || (sign_one && path) || sign_one == 'general') {
            return (
                <compatibility-chooser-zodiac sign_one={sign_one}>
                    <row className='is-wrap'>
                        <column className='is-6 is-12-mobile'>
                            <div
                                className={`compatibility-chooser${path ? '' : ' chosen'}`}
                                name='path'
                                onMouseLeave={() => this.showDropdown('path', false)}
                                onMouseOver={() => this.showDropdown('path', true)}
                            >
                                <h2 className={path ? path.name : null}>
                                    {this.renderH2Text(path, 1)}
                                </h2>
                                <div className={`compatibility-chooser-dropdown ${showPathDropdown ? 'shown' : ''}`}>
                                    {signs.map(sign => this.renderItem(sign, 'path'))}
                                </div>
                            </div>
                        </column>
                        <column className='is-6 is-12-mobile'>
                            <div
                                className='compatibility-chooser'
                                name='hash'
                                onMouseLeave={() => this.showDropdown('hash', false)}
                                onMouseOver={() => this.showDropdown('hash', true)}
                            >
                                <h2 className={hash ? hash.name : null}>
                                    {this.renderH2Text(hash, 2)}
                                </h2>
                                <div className={`compatibility-chooser-dropdown ${showHashDropdown ? 'shown' : ''}`}>
                                    {signs.map(sign => this.renderItem(sign, 'hash'))}
                                </div>
                            </div>
                        </column>
                    </row>
                </compatibility-chooser-zodiac>
            );
        }

        // eslint-disable-next-line tarot/no-direct-props-access-in-render
        return (<compatibility-chooser-zodiac {...this.props} />);
    }

    renderH2Text = (sign, num) => {
        if (sign) {
            return [
                <span>{sign.name.charAt(0).toUpperCase() + sign.name.slice(1)}</span>,
                <small>{sign.start} - {sign.end}</small>,
            ];
        }

        return (
            <span>Choose Sign {num}</span>
        );
    }

    renderItem = (sign, which) => {
        return (
            <compatibility-chooser-dropdown-item
                className={sign.name}
                onClick={() => this.setSign(sign, which)}
            >
                <span>{sign.name.charAt(0).toUpperCase() + sign.name.slice(1)}</span>
                <small>{sign.start} - {sign.end}</small>
            </compatibility-chooser-dropdown-item>
        );
    }

    setSign = (sign, which) => {
        const value = which == 'path' ? { path: sign } : { hash: sign };
        this.props.set(value);

        this.showDropdown(which, false);
    }

    showDropdown = (which, show) => {
        if (which == 'path') {
            this.setState({
                showPathDropdown: show,
            });
        } else if (which == 'hash') {
            this.setState({
                showHashDropdown: show,
            });
        }
    }
}

const mapStateToProps = (state) => ({
    path: state['compatibility-chooser'] && state['compatibility-chooser'].path,
    hash: state['compatibility-chooser'] && state['compatibility-chooser'].hash,
    signs: state.zodiac && state.zodiac.signs,
    route: state.route,
});

const mapDispatchToProps = (dispatch) => ({
    set: (payload) => dispatch({ type: 'compatibility-chooser:update', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
