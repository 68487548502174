import { Component, h } from 'preact';
import { connect } from 'react-redux';

class ReadingHeaderPrice extends Component {
    componentDidMount() {
        this.on_sale = false;
    }

    render({ price, teeup, subscription }) {
        let render = false;

        if (price) {
            if (price.usd && price.usd.original && price.usd.current) {
                this.on_sale = (price.usd.original - price.usd.current) > 0;
            }

            render = price.usd && price.usd.current !== 0;

            if (teeup && teeup.upsell_upc) {
                render = false;
            }

            if (subscription) {
                render = true;
            }
        }

        if (render) {
            return (
                <reading-header-price>
                    {this.renderPrice()}
                </reading-header-price>
            );
        }

        return (
            <reading-header-price />
        );
    }

    renderOnSale = () => {
        const price = this.props.price;

        if (this.on_sale) {
            return (
                <s>${price.usd.original.toFixed(2)} or {price.kc.original} Karma Coins<sup>&reg;</sup></s>
            );
        }

        return null;
    }

    renderPrice = () => {
        if (this.props.price) {
            const price = this.props.price;
            let text = '';
            // if the member is subscribed
            if (price.usd && price.usd.billing_type == 'subscription') {
                if (price.usd.current == null) { // the member has used all of their daily readings
                    text = `${price.kc.current} Karma Coins<sup>&reg;</sup>`;
                } else {
                    text = '0 Karma Coins<sup>&reg;</sup>';
                }
            } else if (price.usd && price.usd.current !== null) {
                if (price.usd.current != 0) {
                    text = `$${price.usd.current.toFixed(2)}`;
                }
                if (price.kc && price.kc.current != 0) {
                    text = text + ` or ${price.kc.current} Karma Coins<sup>&reg;</sup>`;
                }
            } else if (price.kc && price.kc.current) {
                text = `${price.kc.current} Karma Coins<sup>&reg;</sup>`;
            }
            return (
                <p className='reading-price'>
                    {this.renderOnSale()}
                    <span className={this.on_sale ? 'sale' : ''} dangerouslySetInnerHTML={{ __html: text }} />
                </p>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    price: state.product.price,
    subscription: state.product.subscription,
    teeup: state.product.teeup,
});

export default connect(mapStateToProps)(ReadingHeaderPrice);
