import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import ZodiacSignPicker from './zodiac-sign-picker';
import { MediaQuery as MQ } from '../../shared/media-query';

class Component extends BaseComponent {
    constructor() {
        super();

        this.state = { isOpen: null };
    }

    componentDidMount() {
        document.addEventListener('click', this.handleGlobalClick, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleGlobalClick, true);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.isOpen != this.state.isOpen) {
            return true;
        }

        if (nextProps.logged_in != this.props.logged_in) {
            return true;
        }

        if (nextProps.links != this.props.links) {
            return true;
        }

        return false;
    }

    render({ page_name, url, sign }, { isOpen }) {
        return (
            <zodiac-dropdown
                page_name={page_name}
                url={url}
                sign={sign}
                ref={el => this.el = el}
            >
                <span
                    onClick={() => this.toggle('zodiac')}
                    className={isOpen == 'zodiac' ? 'active' : ''}
                >
                    {MQ.isMobile() ? 'Choose a Sign' : 'Choose Another Sign'}
                </span>
                <div>
                    {this.renderDropdown()}
                </div>
            </zodiac-dropdown>
        );
    }

    renderDropdown = () => {
        if (this.state.isOpen == 'zodiac') {
            return (
                <ZodiacSignPicker
                    mode='tiles'
                    sign={this.props.sign}
                    base_url={this.props.url}
                    footer_link='true'
                    page_name={this.props.page_name}
                    category='Dropdown'
                />
            );
        }

        return null;
    }

    toggle = (which) => {
        this.setState({
            isOpen: which == this.state.isOpen ? null : which,
        });
    }

    renderLink = ({ label, url }) => {
        return (
            <a className='zodiac-dropdown' href={url} onClick={() => this.trackClick(label)}>
                {label}
            </a>
        );
    }

    trackClick = (label) => {
        this.props.trackEvent(['Click', `VIP:Dropdown_ZodiacPicker-${label}`]);
        this.props.trackPromo(['Dropdown', label]);
    }

    handleGlobalClick = (e) => {
        if (this.state.isOpen && e.target != this.el && !this.el.contains(e.target)) {
            this.setState({ isOpen: null });
        }
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.user && state.user.logged_in,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    trackPromo: (payload) => dispatch({ type: 'promo-tracker:recordInternal', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
