import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import UserMenuMobile from '../user/menu-mobile';
import { slugify } from '../../../shared/util';
import NavArticles from './articles';

class Component extends BaseComponent {
    componentDidMount() {
        this.spyScrolling();
        this.scrollPastUserMenu();
    }

    componentDidUpdate() {
        this.scrollPastUserMenu();
    }

    render({ logged_in, navigation }) {
        delete navigation.psychics;

        return (
            <nav-bar-mobile>
                <nav-left>
                    {this.renderUserLink()}
                    {Object.keys(navigation).map(key => this.renderLeftSection(navigation[key]))}
                </nav-left>
                <nav-right ref={(el) => this.navRight = el}>
                    <div id='nav-my-account' ref={(el) => this.navTop = el}>
                        {logged_in ? <UserMenuMobile /> : null}
                    </div>
                    {Object.keys(navigation).map(key => this.renderRightSection(navigation[key]))}
                </nav-right>
            </nav-bar-mobile>
        );
    }

    renderUserLink = () => {
        if (this.props.user.logged_in) {
            const total_kcs = this.props.user.kcs.total.toLocaleString();
            const avatar = this.props.user.avatar_url
                || 'https://gfx.tarot.com/images/site/tarot/ui/avatar-unknown.jpg';

            return (
                <a
                    href='#nav-my-account'
                    className='nav-left-my-account'
                    onClick={(e) => this.navClickHandler(e, 'my-account', `MyAccount_${this.props.track_loli}`)}
                >
                    <section className='user-header'>
                        <div className='avatar-mask'>
                            <img src={avatar} className='avatar' alt='User profile picture' />
                        </div>
                        <div className='account-details my-account'>
                            <span className='is-h5'>My Account</span>
                            <p>{total_kcs} Karma Coins<sup>©</sup></p>
                        </div>
                    </section>
                </a>
            );
        }

        return null;
    }

    renderLeftSection = (item) => {
        const name = slugify(item.name);
        return (
            <a
                href={`#nav-${name}`}
                className={`nav-left-${name}${name == 'tarot' ? ' active' : ''}`}
                onClick={(e) => this.navClickHandler(e, name, `${item.name}_${this.props.track_loli}`)}
            >
                <span className='is-h5'>{item.name}</span>
            </a>
        );
    }

    renderRightSection = (item) => {
        return (
            <div id={`nav-${slugify(item.name)}`}>
                {!this.props.logged_in && item.name == 'Tarot' ? <UserMenuMobile /> : null}
                {this.renderSubmenus(item)}
            </div>
        );
    }

    renderSubmenus = (item) => {
        if (item.submenu) {
            return [
                item.submenu.map(subitem => this.renderSubmenu(item, subitem)),
                this.renderNavArticles(item),
            ];
        }

        return null;
    }

    renderSubmenu = (item, subitem) => {
        return (
            <div className='submenu-category nav-mobile'>
                <a
                    href={subitem.link}
                    target={subitem.target || ''}
                    rel={subitem.rel || ''}
                    onClick={() => this.trackClick(`${item.name} - ${subitem.name}`,
                        `${item.name}_${this.props.track_loli}:${subitem.name}`)}
                >
                    {subitem.name}
                </a>
                <ul>
                    {subitem.links.map(destination => this.renderSubmenuList(item, destination))}
                </ul>
            </div>
        );
    }

    renderSubmenuList = (item, destination) => {
        if (destination.logged_in && this.props.logged_in) {
            return (
                <li>
                    <a
                        href={destination.logged_in.link}
                        target={destination.target || ''}
                        rel={destination.rel || ''}
                        className='nav-link'
                        onClick={() => this.trackClick(
                            `${item.name} - ${destination.logged_in.name}`,
                            `${item.name}_${this.props.track_loli}:${destination.logged_in.name}`
                        )}
                    >
                        {destination.logged_in.name}
                    </a>
                </li>
            );
        }

        return (
            <li>
                <a
                    href={destination.link}
                    target={destination.target || ''}
                    rel={destination.rel || ''}
                    className='nav-link'
                    onClick={() => this.trackClick(
                        `${item.name} - ${destination.name}`,
                        `${item.name}_${this.props.track_loli}:${destination.name}`
                    )}
                >
                    {destination.name}
                </a>
            </li>
        );
    }

    renderNavArticles = (item) => {
        if (item.articles) {
            return (
                <NavArticles
                    category={item.name}
                    header={item.articles.name}
                    tag={item.articles.tag}
                    url={item.articles.url}
                />
            );
        }

        return null;
    }

    spyScrolling = () => {
        const navRight = document.querySelector('nav-right');

        navRight.onscroll = () => {
            const sections = document.querySelectorAll('nav-right > div');
            const scrollPos = navRight.scrollTop;

            for (let s in sections) {
                if (sections[s].offsetTop <= scrollPos) {
                    const active = document.querySelector('nav-left a.active');
                    if (active && active.classList) {
                        active.classList.remove('active');
                    }

                    const link = document.querySelector(`nav-left .nav-left-${slugify(sections[s].id.replace('nav-', ''))}`); //eslint-disable-line max-len
                    if (link && link.classList) {
                        link.classList.add('active');
                    }
                }
            }
        };
    };

    scrollPastUserMenu = () => {
        if (this.props.logged_in) {
            this.navRight.scrollBy(0, this.navTop.offsetHeight + 1);
        } else {
            this.navRight.scrollTop = 0;
        }
    }

    navClickHandler = (e, label, info) => {
        this.trackClick(label, info);
        e.preventDefault();

        this.navRight.scroll({
            top: this.navRight.querySelector(`#nav-${label}`).offsetTop + 5,
            left: 0,
            behavior: 'auto',
        });
    }

    trackClick = (label, info) => {
        this.props.trackEvent([
            'Click',
            `Nav_Main:${info}`,
        ]);
        this.props.trackPromo(['Navigation', label]);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.user && state.user.logged_in,
    navigation: state.navigation,
    user: state.user,
    track_loli: state.user && state.user.logged_in ? 'LI' : 'LO',
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    trackPromo: (payload) => dispatch({ type: 'promo-tracker:recordInternal', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
