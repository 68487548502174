import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';

class Component extends BaseComponent {
    shouldComponentUpdate(nextProps) {
        if (nextProps.user != this.props.user) {
            return true;
        }

        return false;
    }

    render({ user, placement, vip_subscribed }) {
        const config = [
            {
                id: 'karmacoins',
                link: '/store/karma-coins',
                label: 'My Account - Karma Coins© Balance',
                ga4_info: 'KCStore',
                title: 'Karma Coins Store',
                subtext: `You Have ${
                    user
                    && user.kcs
                    && user.kcs.total
                    && user.kcs.total.toLocaleString()
                } Karma Coins`,
            },
            {
                id: 'journal',
                link: '/member/journal',
                label: 'My Journal',
                ga4_info: 'Journal',
                title: 'Your Readings',
                subtext: 'View Your Readings Journal',
            },
            {
                id: 'vip',
                link: vip_subscribed ? '/vip/daily-horoscope' : '/readings-reports/subscription/vip-membership',
                label: 'VIP',
                ga4_info: 'VIP',
                title: `${vip_subscribed ? 'My' : 'Try Our'} VIP Membership`,
                subtext: 'Your Unique Horoscopes',
            },
            {
                id: 'wheel',
                link: '/readings-reports/astrology-reports/your-birth-chart',
                label: 'My Account - Birth Chart',
                ga4_info: 'BirthChart',
                title: 'Your Birth Chart',
                subtext: 'View Your Personal Astrology',
            },
            {
                id: 'settings',
                link: '/member/settings',
                label: 'My Account - Settings',
                ga4_info: 'Settings',
                title: 'Account Settings',
                subtext: 'Set Your Favorite Tarot Deck',
            },
            {
                id: 'logout',
                link: '#logout',
                label: 'Log Out',
                ga4_info: 'LogOut',
                title: 'Log Out',
            },
        ];

        return (
            <ul className='user-li-nav'>
                {config.map((item) => (
                    <li className={`ub-${item.id}`} key={item.id}>
                        <a
                            href={item.link}
                            data-label={
                                placement == 'panel'
                                    ? `YourAccount:${item.title}`
                                    : item.label
                            }
                            data-info={item.ga4_info}
                            onClick={this.trackClick}
                        >
                            <span />
                            <div className='account-details'>
                                <h5>{item.title}</h5>
                                {item.subtext && (<p>{item.subtext}</p>)}
                            </div>
                        </a>
                    </li>
                ))}
            </ul>
        );
    }

    trackClick = (e) => {
        const category = `${this.props.placement == 'panel' ? 'Mobile ' : ''}Navigation`;

        this.props.trackEvent([
            'Click',
            `Nav_${
                this.props.placement == 'panel'
                    ? 'Mobile_Sticky:Your'
                    : 'Main:My'
            }Account_${this.props.track_loli}:${e.currentTarget.dataset.info}`,
        ]);
        this.props.trackPromo([category, e.currentTarget.dataset.label]);
    };
}

const mapStateToProps = (state) => ({
    user: state.user,
    vip_subscribed: state.user && state.user.subscriptions && state.user.subscriptions.vip,
    track_loli: state.user && state.user.logged_in ? 'LI' : 'LO',
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    trackPromo: (payload) => dispatch({ type: 'promo-tracker:recordInternal', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
