import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';

class Component extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            offer: {
                tid: '9I2LOT8O',
                long_label: '10 Minutes $1.99',
                short_label: '10 Min',
            },
        };

        props.init({
            size: props.size || 1,
            offer: this.state.offer,
        });
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.keen != this.props.keen) {
            return true;
        }

        return false;
    }

    render({ keen, mode }) {
        if (Object.keys(keen).length) {
            return (
                <keen mode={mode}>
                    <row className='is-wrap'>
                        {Object.keys(keen).map(key => this.renderAdvisor(keen[key]))}
                    </row>
                </keen>
            );
        }

        return (<keen {...this.props} />); //eslint-disable-line tarot/no-direct-props-access-in-render
    }

    renderAdvisor = (advisor) => {
        return (
            <column className='is-6 is-12-mobile has-padding-40-bottom'>
                <a
                    href={`https://www.keen.com${advisor.detailsUrl}&pmode=1&tid=${this.state.offer.tid}&utm_source=tarotcom&utm_medium=b2b&utm_campaign=general&utm_term=advisorlistLP&utm_content=5min1`} //eslint-disable-line max-len
                    target='_blank'
                    rel='noopener sponsored noreferrer'
                    onClick={this.trackClick}
                >
                    <row>
                        <column className='is-4'>
                            <div className='is-relative'>
                                <img
                                    loading='lazy'
                                    src={advisor.pictureURL}
                                    alt={advisor.name}
                                    height='145'
                                    width='145'
                                />
                                {this.renderAvailability(advisor.chatAvailability)}
                            </div>
                        </column>
                        <column className='is-8 has-padding-20-left has-padding-20-right'>
                            <h3 className='has-margin-10-bottom'>{advisor.name}</h3>
                            <p className='has-margin-10-bottom has-line-height-25'>{advisor.listingTitle}</p>
                            <button className='button-black is-pill'>
                                Connect to Advisor
                            </button>
                        </column>
                    </row>
                </a>
            </column>
        );
    }

    renderAvailability = (availability) => {
        if (availability !== 0) {
            return (
                <div className='online-now'>
                    <img
                        src='https://gfx.tarot.com/images/daily-pages/daily-icons/online-now-icon.svg'
                        alt='online now'
                        width='50'
                        height='50'
                    />
                </div>
            );
        }

        return null;
    }

    trackClick = () => {
        this.props.trackEvent([
            'Click',
            `Psychics_LP:KeenAPI-${this.state.offer.short_label}`,
        ]);
    }
}

const mapStateToProps = (state) => ({
    keen: state.keen,
    logged_in: state.user && state.user.logged_in,
});

const mapDispatchToProps = (dispatch) => ({
    init: (payload) => dispatch({ type: 'keen:init', payload: payload }),
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);