import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';

class Component extends BaseComponent {
    componentDidMount() {
        this.inSevenDays = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
        let upc = null;
        let url = null;
        let upsells_unused = [];
        let upsells_used = this.props.upsells_used || [];

        this.props.upsells.forEach(upsell => {
            if (upsells_used.indexOf(upsell.upsell_upc) == -1) {
                upsells_unused.push(upsell);
            }
        });

        if (upsells_unused.length) { // still have unused upsells for this product
            //use the first one that is left
            upc = upsells_unused[0].upsell_upc;
            url = new URL(upsells_unused[0].description.product_url).pathname;
        } else { // all available upsells for this product have been used
            // clear all upsells for this product from cookie
            upsells_used = upsells_used.filter(upsell => !this.props.upsells.some(u => u.upsell_upc == upsell));

            // start with first available upsell
            upc = this.props.upsells[0].upsell_upc;
            url = new URL(this.props.upsells[0].description.product_url).pathname;
        }

        this.upsells_used = upsells_used;
        this.upsell_upc = upc;
        this.upsell_url = url;
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.logged_in != this.props.logged_in) {
            return true;
        }

        if (nextProps.upsells_used != this.props.upsells_used) {
            return true;
        }

        if (nextProps.upsells != this.props.upsells) {
            return true;
        }

        return false;
    }

    render({ upsells, upc }) {
        if (upsells && upsells.length) {
            return (
                <upsell-button upc={upc}>
                    <a
                        className='button-pink-alternate is-block-mobile'
                        href={this.upsell_url}
                        onClick={this.handleClick}
                    >
                        SUGGESTED
                        <span className='is-hidden-mobile'> </span>
                        <br className='is-visible-mobile' />
                        READING
                    </a>
                </upsell-button>
            );
        }

        return null;
    }

    handleClick = (e) => {
        e.preventDefault();
        const category = 'Tarot Reading Upsell';
        const label = `Suggested: ${this.props.upc}`;

        this.props.trackPromo([
            category,
            label,
        ]);

        this.props.trackEvent([
            'Click',
            `Readings_${this.props.upc}:Suggested`,
        ]);

        this.upsells_used.push(this.upsell_upc);

        this.props.setCookie({
            name: 'upsells_used',
            value: this.upsells_used,
            opts: {
                expires: this.inSevenDays,
            },
        });

        window.location.assign(this.upsell_url);
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.user && state.user.logged_in,
    upsells_used: state.cookie && state.cookie.upsells_used,
    upsells: state.product && state.product.upsells,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (action) => dispatch({ type: 'ga:event', payload: action }),
    trackPromo: (payload) => dispatch({ type: 'promo-tracker:recordInternal', payload: payload }),
    setCookie: (payload) => dispatch({ type: 'cookie:set', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
