import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { hasTouchEvents } from '../../../shared/util';
import MenuLogin from './menu-login';
import MenuDetails from './menu-details';

class Component extends BaseComponent {
    shouldComponentUpdate(nextProps) {
        if (nextProps.user != this.props.user) {
            return true;
        }

        return false;
    }

    render({ user }) {
        return (
            <user-menu>
                {user.logged_in ? this.renderLoggedIn() : (<MenuLogin />)}
            </user-menu>
        );
    }

    renderLoggedIn = () => {
        return (
            <div className='user-li'>
                {this.renderMyAccount()}
                <MenuDetails />
            </div>
        );
    }

    renderMyAccount = () => {
        const avatar = this.props.user.avatar_url || 'https://gfx.tarot.com/images/site/tarot/ui/avatar-unknown.jpg';

        if (hasTouchEvents) {
            return (
                <div className='my-account'>
                    <div className='avatar-mask'>
                        <img src={avatar} height='40' width='40' className='avatar' alt='User profile picture' />
                    </div>
                    <div className='account-details'>
                        <h5>My Account</h5>
                        <p>{this.props.user.kcs.total.toLocaleString()} Karma Coins<sup>®</sup></p>
                    </div>
                </div>
            );
        }

        return (
            <a
                className='my-account'
                href='/member/settings'
                data-label='My Account - Karma Coins®'
                data-info={`Nav_Main:MyAccount_${this.props.track_loli}`}
                onClick={this.trackClick}
            >
                <div className='avatar-mask'>
                    <img src={avatar} height='40' width='40' alt='User profile picture' className='avatar' />
                </div>
                <div className='account-details'>
                    <h5>My Account</h5>
                    <p>{this.props.user.kcs.total.toLocaleString()} Karma Coins<sup>®</sup></p>
                </div>
            </a>
        );
    }

    trackClick = (e) => {
        this.props.trackEvent([
            'Click',
            e.currentTarget.dataset.info,
        ]);
        this.props.trackPromo(['Navigation', e.currentTarget.dataset.label]);
    };
}

const mapStateToProps = (state) => ({
    user: state.user,
    track_loli: state.user && state.user.logged_in ? 'LI' : 'LO',
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    trackPromo: (payload) => dispatch({ type: 'promo-tracker:recordInternal', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
