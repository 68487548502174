import { Component, h } from 'preact';
import { connect } from 'react-redux';

class PromoMessage extends Component {
    componentDidMount() {
        this.props.requireProductMessage(this.props.placement, this.props.product_result_slug);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.logged_in != this.props.logged_in) {
            return true;
        }

        if (nextProps.message != this.props.message) {
            return true;
        }

        return false;
    }

    render({ message, code, placement, product_result_slug, trackViewPromotion }) {
        if (message) {
            trackViewPromotion({
                placement: placement,
                id: code,
                name: `Promo:${placement}-${code}`,
            });

            return (
                <product-message placement={placement} product_result_slug={product_result_slug}>
                    <p dangerouslySetInnerHTML={{ __html: message }} />
                </product-message>
            );
        }

        return (<product-message placement={placement} product_result_slug={product_result_slug} />);
    }
}

const mapStateToProps = (state, { placement }) => ({
    logged_in: state.user && state.user.logged_in,
    message: state['product-message'] && state['product-message'][placement]
        && state['product-message'][placement].message,
    code: state['product-message'] && state['product-message'][placement]
        && state['product-message'][placement].code,
});

const mapDispatchToProps = (dispatch) => ({
    requireProductMessage: (placement, product_result_slug) => dispatch({
        type: 'product-message:require',
        payload: {
            placement: placement,
            product_result_slug: product_result_slug,
        },
    }),
    trackViewPromotion: (payload) => dispatch({ type: 'ga:view_promotion', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoMessage);
