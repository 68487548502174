import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Suspense, lazy } from 'preact/compat';
const SelectGroup = lazy(
    () => import(/* webpackChunkName: "select-group" */ '../../shared/form/select-group')
);

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.locale('en');

class Component extends BaseComponent {
    constructor() {
        super();

        this.articleCodex = {
            4: 'death-emperor',
            5: 'hierophant-temperance',
            6: 'lovers-devil',
            7: 'chariot-tower',
            8: 'strength-star',
            9: 'hermit-moon',
            10: 'magician-wheel',
            11: 'high-priestess-justice',
            12: 'empress-hanged-man',
            13: 'emperor-death',
            14: 'hierophant-temperance',
            15: 'lovers-devil',
            16: 'chariot-tower',
            17: 'strength-star',
            18: 'hermit-moon',
            19: 'magician-wheel-sun',
            20: 'high-priestess-judgement',
            21: 'empress-world',
            22: 'death-emperor',
            23: 'hierophant-temperance',
            24: 'lovers-devil',
        };

        const days = Array.from({ length: 31 }, (_, i) => i + 1);
        const months = dayjs.months();
        const years = Array.from({ length: 101 }, (_, i) => dayjs().year() - i);

        this.state = {
            formError: null,
            month: {
                value: '',
                error: false,
            },
            months: months,
            day: {
                value: '',
                error: false,
            },
            days: days,
            year: {
                value: '',
                error: false,
            },
            years: years,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState != this.state) {
            return true;
        }

        return false;
    }

    render(props, { formError, ...state }) {
        return (
            <birth-card-calculator className='is-block has-text-center has-padding-20-top'>
                <h3>
                    Calculate Your<br className='is-visible-mobile' />
                    <span className='is-hidden-mobile'> </span>Tarot Birth Cards
                </h3>
                <h4>Enter your birth date</h4>
                <form onSubmit={this.submit} className='has-padding-20'>
                    <row className='is-wrap'>
                        {['month', 'day', 'year'].map((period) => (
                            <column className='is-4 is-12-mobile has-padding-5-right'>
                                <Suspense fallback={<div className='skeleton' />}>
                                    <SelectGroup
                                        name={period}
                                        value={state[period].value}
                                        placeholder={period.toUpperCase()}
                                        options={state[period + 's']}
                                        error={state[period].error}
                                        onChange={this.setFormValues}
                                    />
                                </Suspense>
                            </column>
                        ))}
                        <column className='is-12'>
                            <button className='has-margin-10-top button'>Calculate Tarot Birth Cards</button>
                            {formError && <p className='is-error'>{formError}</p>}
                        </column>
                    </row>
                </form>
            </birth-card-calculator>
        );
    }

    setFormValues = (e) => {
        let state = [];

        if ((e.type == 'keyup' && e.key == 'Enter') || e.type == 'change') {
            state[e.target.name] = {
                value: e.target.value,
                error: false,
            };

            state.formError = null;
        }

        this.setState(state);
    }

    calculateBirthCardSlug = (datestring) => {
        // Break birthdate into 4 groups of 2 digits each
        const [month, day, year1, year2] = datestring.match(/.{1,2}/g);

        // Calculate sum of birthdate groups
        const sum = parseInt(month) + parseInt(day) + parseInt(year1) + parseInt(year2);

        const sumStr = sum.toString();
        let finalSum;

        if (sumStr.length === 3) {
            // If the sum is a 3-digit number, add the first two digits together, then add the third digit to that sum
            finalSum = parseInt(sumStr.substring(0, 2)) + parseInt(sumStr[2]);
        } else {
            // If the sum is a 2-digit number, add the two digits together
            finalSum = parseInt(sumStr[0]) + parseInt(sumStr[1]);
        }

        // first card = finalSum, indexed starting with 1 = magician
        // reduce this to a single digit to get the second card. ex: first card: 8, second card: 17 (8=1+7)
        // case finalSum = single digit: use finalSum as the second card and reverse the equation to get the first
        //    ex: second card: 8, first card: 17 (8=1+7) - take 8, subtract 1 to get 7. then prepend "1" to get 17
        // case finalSum > 21: add the two digits together to get the First Card
        // case finalSum = 19: this sum will have 3 card. This is because 1 + 9 = 10, and 1 + 0 = 1,
        //      effectively making the cards:  sun (19), wheel (10), and magician (1).
        // since all we need is the slug, this logic has been simplified to create the articleCodex object.

        return this.articleCodex[finalSum];
    }

    submit = (e) => {
        e.preventDefault();

        const state = this.state;
        state.formError = null;

        ['month', 'day', 'year'].forEach((field) => {
            if (!state[field].value) {
                state[field].error = true;
                state.formError = 'Please fill out highlighted areas above to submit';
            }
        });

        if (!state.formError) {
            const month = dayjs.months().indexOf(this.state.month.value) + 1;
            const date = dayjs(`${month}/${this.state.day.value}/${this.state.year.value}`,
                'M/D/YYYY').format('MM/DD/YYYY');

            if (dayjs(date, 'MM/DD/YYYY', true).isValid()) {
                const slug = this.calculateBirthCardSlug(dayjs(date).format('MMDDYYYY'));

                this.props.trackEvent([
                    'Click',
                    `Calculator_BirthCards:${slug}`,
                ]);

                window.location.href = `/tarot/birth-cards-${slug}`;
            } else {
                state.formError = 'Please enter a valid date to submit';
            }
        }

        this.setState(state);
    }
}

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
});

export default connect(null, mapDispatchToProps)(Component);
