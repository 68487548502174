import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';

class Component extends BaseComponent {
    /* eslint-disable tarot/no-direct-props-access-in-render */
    render({ name, error, value }) {
        return (
            <textarea-group name={name} className={error ? 'has-error' : ''}>
                {this.renderLabel()}
                <textarea {...this.props} className={error ? 'error' : ''}>{value}</textarea>
                {this.renderError()}
            </textarea-group>
        );
    }
    /* eslint-enable tarot/no-direct-props-access-in-render */

    renderLabel = () => {
        if (this.props.label) {
            return (
                <label dangerouslySetInnerHTML={{ __html: this.props.label }} />
            );
        }

        return null;
    }

    renderError = () => {
        if (this.props.error) {
            return (
                <p className='is-error'>{this.props.error}</p>
            );
        }

        return null;
    }
}

export default connect()(Component);