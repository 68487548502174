import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';

class Component extends BaseComponent {
    componentDidMount() {
        this.props.sendBrowsePageEvent();
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (<cordial />);
    }
}

const mapDispatchToProps = (dispatch) => ({
    sendBrowsePageEvent: () => dispatch({ type: 'cordial:page' }),
});

export default connect(null, mapDispatchToProps)(Component);
