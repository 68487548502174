import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import MenuLogin from './menu-login';
import MenuDetails from './menu-details';

class Component extends BaseComponent {
    shouldComponentUpdate(nextProps) {
        if (nextProps.logged_in != this.props.logged_in) {
            return true;
        }

        return false;
    }

    render({ logged_in }) {
        if (logged_in) {
            return (
                <user-menu-mobile>
                    <section className='user-details'>
                        <MenuDetails />
                    </section>
                </user-menu-mobile>
            );
        }

        return (<MenuLogin />);
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.user && state.user.logged_in,
});

export default connect(mapStateToProps)(Component);
