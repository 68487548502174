import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { MediaQuery as MQ } from '../../../shared/media-query';
import { hasTouchEvents } from '../../../shared/util';
import NavbarMobile from './bar-mobile';

class Component extends BaseComponent {
    constructor() {
        super();
        this.state = { width: window.innerWidth };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.width >= 900 && this.state.width < 900) {
            return true;
        }

        if (nextState.width < 900 && this.state.width >= 900) {
            return true;
        }

        if (nextProps.show_nav != this.props.show_nav) {
            return true;
        }

        return false;
    }

    render({ show_nav }) {
        if (show_nav != undefined &&
            (MQ.isMobile() || MQ.isTabletPortrait() || (hasTouchEvents && MQ.isPortrait()))
        ) {
            return (
                <mobile-nav className={show_nav && 'show-nav'}>
                    <NavbarMobile />
                </mobile-nav>
            );
        }

        return (<mobile-nav />);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    }
}

const mapStateToProps = (state) => ({
    show_nav: state.ui && state.ui.show_nav,
});

const mapDispatchToProps = (dispatch) => ({
    showNavUpdate: (payload) => dispatch({ type: 'ui:update', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);