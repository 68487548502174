import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import _ from 'underscore';
import { MediaQuery as MQ } from '../../../shared/media-query';

class Component extends BaseComponent {
    render({ articles, tag, category }) {
        if (MQ.isMobile() || MQ.isTabletPortrait()) {
            if (category === 'Tarot') {
                return this.renderArticles(articles.slice(0, 2), 'The Latest');
            } else if (category === 'Horoscopes') {
                return this.renderArticles(articles.slice(2, 4), 'More For Today');
            } else if (category === 'More Insight') {
                return this.renderArticles(articles.slice(0, 4), 'The Latest');
            }

            return (<nav-articles tag={tag} category={category} />);
        } else if (tag != 'psychics') {
            return this.renderArticles(articles.slice(0, 4), 'The Latest');
        }

        return (<nav-articles tag={tag} category={category} />);
    }

    renderArticles = (articles, headline) => {
        return (
            <nav-articles tag={this.props.tag} category={this.props.category}>
                <a
                    href='/articles'
                    onClick={() => this.trackClick(
                        `Nav_Main:${this.props.category}_${this.props.track_loli}:${headline}`
                    )}
                >
                    {headline}
                </a>
                <row className='is-wrap'>
                    {articles.map(this.renderArticle)}
                </row>
            </nav-articles>
        );
    }

    renderArticle = (article, i) => {
        const size = MQ.isMobile() || MQ.isTabletPortrait() ? '380x235' : '50x50';
        const image_src = article.picture_frag
            ? `https://gfx.tarot.com/images/feeds/${size}/${article.picture_frag}-${size}.jpg`
            : `https://gfx.tarot.com/images/feeds/${size}/tarot-hand-${size}.jpg`;

        return (
            <column className='is-6 is-12-tablet-portrait is-12-mobile'>
                <a
                    href={article.url}
                    onClick={() => this.trackClick(
                        `Nav_Main:${this.props.category}_${this.props.track_loli}:TopStories${i + 1}`
                    )}
                >
                    <row className='is-wrap'>
                        <column className='is-4 is-12-tablet-portrait is-12-mobile'>
                            <img
                                src={image_src}
                                loading='lazy'
                                height='235'
                                width='380'
                                alt={article.image_alt}
                            />
                        </column>
                        <column className='is-8 is-12-tablet-portrait is-12-mobile'>
                            <p>{article.title}</p>
                        </column>
                    </row>
                </a>
            </column>
        );
    }

    trackClick = (info) => {
        this.props.trackEvent(['Click', info]);
    }
}

const mapStateToProps = (state) => ({
    articles: state.content && state.content.featured_articles && state.content.featured_articles.data,
    track_loli: state.user && state.user.logged_in ? 'LI' : 'LO',
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
