import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

class Component extends BaseComponent {
    render({ close, profileModal, submitting }) {
        if (profileModal) {
            return (
                <profile-modal>
                    <dialog-header>
                        <button onClick={close}>×</button>
                    </dialog-header>
                    <dialog-content>
                        <h4 className='has-text-center' dangerouslySetInnerHTML={{ __html: profileModal.message }} />
                        {this.renderDelete()}
                        {this.renderUpdate()}
                        <row className='is-wrap'>
                            <column className='is-12 has-text-center has-padding-20-top'>
                                <a href='#' className='button' onClick={this.processProfile}>
                                    {submitting ? 'Submitting...' : profileModal.buttonText}
                                </a>
                            </column>
                            <column className='is-12 has-text-center has-padding-15-top'>
                                <a href='#' onClick={this.close}>
                                    {profileModal.linkText}
                                </a>
                            </column>
                        </row>
                    </dialog-content>
                </profile-modal>
            );
        }

        return (<profile-modal {...this.props} />); //eslint-disable-line tarot/no-direct-props-access-in-render
    }

    renderDelete = () => {
        if (this.props.profileModal.type == 'delete') {
            const profile = this.props.profileModal.profile;

            return (
                <row className='is-wrap has-margin-15-top'>
                    <column className='is-6 has-weight-500 has-text-right has-padding-5-right'>
                        Name:
                    </column>
                    <column className='is-6 has-padding-5-left'>
                        {profile.first_name || 'Unknown'}
                    </column>
                    <column className='is-6 has-weight-500 has-text-right has-padding-5-right'>
                        Gender:
                    </column>
                    <column className='is-6 has-padding-5-left'>
                        {
                            profile.gender
                                ? `${profile.gender[0].toUpperCase()}${profile.gender.slice(1)}`
                                : 'Unknown'
                        }
                    </column>
                    <column className='is-6 has-weight-500 has-text-right has-padding-5-right'>
                        Birth Date:
                    </column>
                    <column className='is-6 has-padding-5-left'>
                        {dayjs(profile.birth_date, 'YYYY-MM-DD').format('MMMM D, YYYY')}
                    </column>
                    <column className='is-6 has-weight-500 has-text-right has-padding-5-right'>
                        Birth Time:
                    </column>
                    <column className='is-6 has-padding-5-left'>
                        {
                            profile.birth_time
                                ? dayjs(profile.birth_time, 'HH:mm').format('h:mma')
                                : 'Unknown'
                        }
                    </column>
                    <column className='is-6 has-weight-500 has-text-right has-padding-5-right'>
                        Birth Location:
                    </column>
                    <column className='is-6 has-padding-5-left'>
                        {this.renderBirthLocation()}
                    </column>
                    <column className='is-6 has-weight-500 has-text-right has-padding-5-right'>
                        Current Location:
                    </column>
                    <column className='is-6 has-padding-5-left'>
                        {this.renderCurrentLocation()}
                    </column>
                </row>
            );
        }

        return null;
    }

    renderBirthLocation = () => {
        const profile = this.props.profileModal.profile;
        let string = profile.birth_city || '';

        if (profile.birth_state) {
            string = `${string}, ${profile.birth_state}`;
        }

        if (profile.birth_country) {
            string = `${string} ${profile.birth_country}`;
        }

        return string || 'Unknown';
    }

    renderCurrentLocation = () => {
        const profile = this.props.profileModal.profile;
        let string = profile.current_city || '';

        if (profile.current_state) {
            string = `${string}, ${profile.current_state}`;
        }

        if (profile.current_country) {
            string = `${string} ${profile.current_country}`;
        }

        return string || 'Unknown';
    }

    renderUpdate = () => {
        if (this.props.profileModal.type == 'update') {
            return (
                <row className='is-wrap has-margin-5-top'>
                    <column className='is-6 is-12-mobile current-profile has-padding-10'>
                        <h4>Current Profile:</h4>
                        <row className='is-wrap'>
                            {this.renderDifferences('current')}
                        </row>
                    </column>
                    <column className='is-6 is-12-mobile has-padding-10'>
                        <h4>Profile Update:</h4>
                        <row className='is-wrap'>
                            {this.renderDifferences('updates')}
                        </row>
                    </column>
                </row>
            );
        }

        return null;
    }

    /* eslint-disable complexity, max-len */
    renderDifferences = (which) => {
        const data = this.props.profileModal[which];
        const profileMap = {
            birth_name: 'Name',
            gender: 'Gender',
            birth_date: 'Birth Date',
            birth_time: 'Birth Time',
            male: 'Male',
            female: 'Female',
            birth_location: 'Birth Location',
            current_location: 'Current Location',
        };

        return Object.keys(data).map(key => {
            if (key == 'birth_name') {
                return [
                    <column className='is-6 has-weight-500 has-padding-5-right has-padding-5-bottom'>
                        {profileMap[key]}:
                    </column>,
                    <column className='is-6 has-padding-5-left has-padding-5-bottom'>
                        {data[key].value}
                    </column>,
                ];
            } else if (key == 'gender') {
                return [
                    <column className='is-6 has-weight-500 has-padding-5-right has-padding-5-bottom'>
                        {profileMap[key]}:
                    </column>,
                    <column className='is-6 has-padding-5-left has-padding-5-bottom'>
                        {profileMap[data[key].value]}
                    </column>,
                ];
            } else if (key == 'birth_date') {
                return [
                    <column className='is-6 has-weight-500 has-padding-5-right has-padding-5-bottom'>
                        {profileMap[key]}:
                    </column>,
                    <column className='is-6 has-padding-5-left has-padding-5-bottom'>
                        {dayjs(data[key].value, 'YYYY-MM-DD').format('MMMM D, YYYY')}
                    </column>,
                ];
            } else if (key == 'birth_time') {
                const currentBirthTime = dayjs(this.props.profileModal.current.birth_time.value, 'hh:mm');
                const updatedBirthTime = dayjs(this.props.profileModal.updates.birth_time.value, 'hh:mm');

                if (currentBirthTime.isValid()) {
                    return [
                        <column className='is-6 has-weight-500 has-padding-5-right has-padding-5-bottom'>
                            {profileMap[key]}:
                        </column>,
                        <column className='is-6 has-padding-5-left has-padding-5-bottom'>
                            {data[key].value && data[key].value != 'unknown'
                                ? dayjs(data[key].value, 'HH:mm').format('h:mma')
                                : 'Unknown'}
                        </column>,
                    ];
                } else if (updatedBirthTime.isValid()) {
                    return [
                        <column className='is-6 has-weight-500 has-padding-5-right has-padding-5-bottom'>
                            {profileMap[key]}:
                        </column>,
                        <column className='is-6 has-padding-5-left has-padding-5-bottom'>
                            {data[key].value && data[key].value != 'unknown'
                                ? dayjs(data[key].value, 'HH:mm').format('h:mma')
                                : 'Unknown'}
                        </column>,
                    ];
                }
            } else if (['birth_location', 'current_location'].includes(key)) {
                const prefix = key.replace('_location', '');

                return [
                    <column className='is-12 has-weight-500 has-padding-5-bottom'>
                        {profileMap[key]}
                    </column>,
                    <column className='is-12 has-padding-5-bottom'>
                        {this.renderCity(data[`${prefix}_location`])}
                        {this.renderState(data[`${prefix}_location`], prefix, which)}
                        {this.renderCountry(data[`${prefix}_location`])}
                    </column>,
                ];
            }

            return null;
        });
    }
    /* eslint-enable complexity, max-len */

    renderCity = (data) => {
        if (data.city) {
            return (
                <row>
                    <column className='is-6 has-padding-5-right has-padding-5-bottom'>
                        City:
                    </column>
                    <column className='is-6 has-padding-5-left has-padding-5-bottom'>
                        {data.city.value}
                    </column>
                </row>
            );
        }

        return null;
    }

    renderState = (data, prefix, which) => {
        if (data.state && data.state.value) {
            if (which == 'updates') {
                return (
                    <row>
                        <column className='is-6 has-padding-5-right has-padding-5-bottom'>
                            State:
                        </column>
                        <column className='is-6 has-padding-5-left has-padding-5-bottom'>
                            {this.props.location[`${prefix}_states`][data.state.value]}
                        </column>
                    </row>
                );
            }

            return (
                <row>
                    <column className='is-6 has-padding-5-right has-padding-5-bottom'>
                        State:
                    </column>
                    <column className='is-6 has-padding-5-left has-padding-5-bottom'>
                        {this.props.user.selected_profile[`${prefix}_state`]}
                    </column>
                </row>
            );
        }

        return null;
    }

    renderCountry = (data) => {
        if (data.country) {
            return (
                <row>
                    <column className='is-6 has-padding-5-right has-padding-5-bottom'>
                       Country:
                    </column>
                    <column className='is-6 has-padding-5-left has-padding-5-bottom'>
                        {this.props.location.countries[data.country.value]}
                    </column>
                </row>
            );
        }

        return null;
    }

    processProfile = (e) => {
        e.preventDefault();

        if (this.props.profileModal.type == 'delete') {
            this.trackClick('AccountSettings:ProfilesManager-Delete');
            this.props.deleteProfile(this.props.profileModal.profile.profile_uuid);
        } else if (this.props.profileModal.type == 'update') {
            this.trackClick('AccountSettings:ProfilesManager-Update');

            let payload = {
                ...this.props.profileModal.payload,
                ...{
                    profile_uuid: this.props.profileModal.profile_uuid,
                    set_submitting: true,
                },
            };

            this.props.saveProfile(payload);
        }
    }

    close = (e) => {
        e.preventDefault();
        this.props.close();
    }

    trackClick = (info) => {
        this.props.trackEvent(['Click', info]);
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    profileModal: state.user && state.user.profileModal,
    submitting: state.product && state.product.submitting,
    location: state.location,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    deleteProfile: (payload) => dispatch({ type: 'profile-manager:delete-profile', payload: payload }),
    saveProfile: (payload) => dispatch({ type: 'profile-manager:save-profile', payload: payload }),
    routeTo: (payload) => dispatch({ type: 'route:to', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
