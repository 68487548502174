import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { isValidEmail } from '../../../shared/util';
import { Suspense, lazy } from 'preact/compat';
const InputGroup = lazy(
    () => import(/* webpackChunkName: "input-group" */ '../../../shared/form/input-group')
);

class Component extends BaseComponent {
    constructor(props) {
        super(props);

        if (props.user && props.user.logged_in) {
            props.close();
        } else {
            props.trackEvent([
                'Open',
                `SiteInteraction:${props.route.charAt(0).toUpperCase()}${props.route.slice(1)}`,
            ]);

            this.state = {
                email: {
                    value: props.user && props.user.email,
                    error: null,
                },
                confirm_email: {
                    value: null,
                    error: null,
                },
                password: {
                    value: null,
                    error: null,
                },
            };
        }
    }

    componentWillUnmount() {
        this.props.userUpdate({
            emailError: null,
            passwordError: null,
        });
    }

    render({
        close,
        emailError,
        passwordError,
        user,
        route,
        mailListType,
        product,
    }, {
        confirm_email,
        email,
        password,
    }) {
        if (user && !user.logged_in) {
            let title = 'Welcome Back!';
            let subtitle = 'We’re so happy to see you again!<br />Sign into your account below.';
            let button_text = 'ENTER';

            if (route == 'login-signup') {
                if (mailListType && mailListType == 'Newsletter') {
                    title = 'Your Subscription Awaits!';
                    subtitle = 'Log in or sign up below<br class="is-visible-mobile" /><span class="is-hidden-mobile"> </span>for your Subscription. It\'s easy!'; //eslint-disable-line max-len
                    button_text = 'SUBMIT';
                } else {
                    title = 'We’re So Glad You’re Here';
                    subtitle = 'Your FREE membership comes with a ton of perks!<br class="is-hidden-mobile" /><span class="is-visible-mobile"> </span>Get started NOW!'; //eslint-disable-line max-len
                }
            } else if (product && product.type) {
                title = `Your ${product.type} Awaits!`;
                subtitle = `Log in or sign up below<br class="is-visible-mobile" /><span class="is-hidden-mobile"> </span>for your ${product.type}. It's easy!`; //eslint-disable-line max-len
                button_text = `GET MY ${product.type.toUpperCase()}!`;
            }

            return (
                <login-modal>
                    <dialog-header>
                        <button onClick={close}>×</button>
                        <h1 className='fancy-title has-text-center' dangerouslySetInnerHTML={{ __html: title }} />
                        <p
                            className='has-text-center has-margin-0-bottom'
                            dangerouslySetInnerHTML={{ __html: subtitle }}
                        />
                    </dialog-header>
                    <dialog-content>
                        <form autoComplete='on' onSubmit={this.validate}>
                            <row className='confirm_row'>
                                <column className='is-12'>
                                    <Suspense fallback={<div className='skeleton' />}>
                                        <InputGroup
                                            type='text'
                                            name='confirm_email'
                                            label='Confirm Your Email'
                                            value={confirm_email.value}
                                            onInput={this.setFormValues}
                                        />
                                    </Suspense>
                                </column>
                            </row>
                            <row>
                                <column className='is-12'>
                                    <Suspense fallback={<div className='skeleton' />}>
                                        <InputGroup
                                            autoFocus
                                            type='text'
                                            name='email'
                                            label='Your Email'
                                            value={email.value}
                                            error={email.error || emailError}
                                            onInput={this.setFormValues}
                                        />
                                    </Suspense>
                                </column>
                            </row>
                            <row>
                                <column className='is-12'>
                                    <Suspense fallback={<div className='skeleton' />}>
                                        <InputGroup
                                            type='password'
                                            name='password'
                                            label='Password'
                                            value={password.value}
                                            error={password.error || passwordError}
                                            onInput={this.setFormValues}
                                        />
                                    </Suspense>
                                </column>
                            </row>
                            <row>
                                <column className='is-12 has-text-center'>
                                    <button onClick={this.validate}>{button_text}</button>
                                    {this.renderLink()}
                                </column>
                            </row>
                        </form>
                    </dialog-content>
                </login-modal>
            );
        }

        return null;
    }

    renderLink = () => {
        if (this.props.route == 'login') {
            return (
                <a
                    href='/password-reset'
                    className='has-padding-10-bottom-mobile'
                    onClick={() => this.trackClick(
                        'Modal_LogInSignUp:ForgotYourPassword'
                    )}
                >
                        Forgot Your<br />Password?
                </a>
            );
        } else if (this.props.route == 'login-signup') {
            return (
                <a href='#login' onClick={() => this.trackClick('Modal_LogInSignUp:LogInHere')}>
                    <span className='has-text-boulder'>
                        Already a<br className='is-visible-mobile' /><span className='is-hidden-mobile'> </span>member?
                    </span>
                    <br />
                    Log In Here
                </a>
            );
        }

        return null;
    }

    setFormValues = (e) => {
        let state = {};

        if ((e.type == 'keyup' && e.key == 'Enter') || e.type == 'change' || e.type == 'input') {
            state[e.target.name] = {
                value: e.target.value,
                error: null,
            };

            this.setState(state);
        }
    }

    validate = (e) => {
        e.preventDefault();

        const state = {
            email: {
                value: this.state.email.value,
                error: isValidEmail(this.state.email.value) ? null : 'Please enter a valid email',
            },
            password: {
                value: this.state.password.value,
                error: this.state.password.value ? this.validatePassword() : 'Please enter a password',
            },
            confirm_email: {
                value: this.state.confirm_email.value || document.querySelector('input[name="confirm_email"]').value,
                error: null,
            },
        };

        this.setState(state);

        if (
            state.confirm_email.value
            ||
            document.querySelector('input[name="confirm_email"]').value
        ) {
            this.props.trackEvent([
                '#login',
                'Bot:Attempted',
            ]);
        }

        if (
            !state.email.error
            &&
            !state.password.error
            &&
            !state.confirm_email.value
            &&
            !document.querySelector('input[name="confirm_email"]').value
        ) {
            this.props.userLogin({
                email: this.state.email.value,
                password: this.state.password.value,
            });
        }
    }

    validatePassword = () => {
        return this.state.password.value.length > 3 ? null : 'Please enter a password longer than 3 characters.';
    }

    trackClick = (info) => {
        this.props.trackEvent(['Click', info]);
    }
}

const mapStateToProps = (state) => ({
    route: state.route,
    product: state.product,
    user: state.user,
    mailListType: state['mail-lists'] && state['mail-lists'].type,
    emailError: state.user && state.user.emailError,
    passwordError: state.user && typeof state.user.passwordError === 'boolean'
        ? 'Password did not match this email'
        : state.user.passwordError,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    userLogin: (payload) => dispatch({ type: 'user:login', payload: payload }),
    userUpdate: (payload) => dispatch({ type: 'user:update', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
