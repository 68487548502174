import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';

class Component extends BaseComponent {
    componentDidMount() {
        const component = this;

        document.addEventListener('click', (e) => {
            let target = null;

            if (e.target.hasAttribute('data-ga-event')) {
                target = e.target;
            } else if (e.target.closest('a[data-ga-event]')) {
                target = e.target.closest('a[data-ga-event]');
            } else if (e.target.closest('li[data-ga-event]')) {
                target = e.target.closest('li[data-ga-event]');
            }

            if (
                target
                &&
                target.hasAttribute('data-action')
                &&
                target.dataset.action.match(/Click/)
            ) {
                component.trackDynamicClick(target);
            }
        });

        document.addEventListener('change', (e) => {
            if (
                e.target.hasAttribute('data-ga-event')
                &&
                e.target.hasAttribute('data-action')
                &&
                e.target.dataset.action.match(/^Select/)
            ) {
                component.trackOneTimeEvent(e.target);
            }
        });
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (<ga-events />);
    }

    trackDynamicClick = (el) => {
        if (el && el.dataset) {
            const category = el.dataset.category;
            const action = el.dataset.action;
            const label = el.dataset.label;
            const info = el.dataset.info;

            if (category && label) {
                if (!el.dataset.noInternalPromo) {
                    this.props.trackPromo([category, label]);
                }

                if (action) {
                    this.props.trackEvent([action, info]);
                }
            }
        }
    }

    trackOneTimeEvent = (el) => {
        if (el && el.dataset) {
            const action = el.dataset.action;
            const info = el.dataset.info;

            this.props.trackEvent([action, info]);

            el.removeAttribute('data-ga-event');
            el.removeAttribute('data-action');
            el.removeAttribute('data-category');
            el.removeAttribute('data-label');
            el.removeAttribute('data-info');
        }
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.user && state.user.logged_in,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (data) => dispatch({ type: 'ga:event', payload: data }),
    trackPromo: (payload) => dispatch({ type: 'promo-tracker:recordInternal', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
