/* global require */
import _ from 'underscore';

export const components = {
    'a[data-is=smooth-scroll]': 'smooth-scroll',
    'auto-login-check': 'auto-login-check',
    'astrology-compatibility': 'compatibility/astrology',
    'birth-card-calculator': 'birth-card-calculator',
    'birthday-select': 'birthday-select',
    'chinese-zodiac-sign-datepicker': 'chinese-zodiac-sign-datepicker',
    'compatibility-chooser': 'compatibility/chooser',
    'cordial': 'cordial',
    'date-picker': 'date-picker',
    'ga-events': 'ga-events',
    'kc-sale-message': 'kc-sale-message',
    'keen': 'keen',
    'keen-slider': 'keen-slider',
    'logged-in': 'logged-in',
    'logged-out': 'logged-out',
    'mobile-menu': 'nav/mobile-menu',
    'mobile-nav': 'nav/mobile-nav',
    'modal': 'modals/index',
    'nav-submenu': 'nav/submenu',
    'panel-loader': 'panel-loader',
    'policy-consent': 'policy-consent',
    'product-message': 'product-message',
    'promo-message': 'promo-message',
    'reading-header-price': 'reading-header-price',
    'sale-message': 'sale-message',
    'service-contact-form': 'service-contact-form',
    'upsell-button': 'upsell-button',
    'user-menu': 'user/menu-desktop.js',
    'zodiac-dropdown': 'zodiac-dropdown',
    'zodiac-sign-picker': 'zodiac-sign-picker',
};

_.each(components, (path, tag) => {
    const { default: Component } = require(`./components/${path}`);

    import(/* webpackMode: "weak" */ './index')
        .then((index) => {
            index.register(tag, Component);
        })
        .catch(() => {});
});