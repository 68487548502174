import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import _ from 'underscore';

dayjs.extend(customParseFormat);

class Component extends BaseComponent {
    constructor(props) {
        super(props);

        const months = _.range(12);
        const days = _.range(31);
        const years = props.direction == 'future'
            ? _.range( dayjs().year() - 1, dayjs().year() + 10 )
            : _.range( dayjs().year(), dayjs().year() - 100, -1 );

        this.state = {
            month: null,
            months: months,
            day: null,
            days: days,
            year: null,
            years: years,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.month != this.state.month) {
            this.getDays(nextState);
            this.propagate(nextState);
        }

        if (nextState.year != this.state.year) {
            this.getDays(nextState);
            this.propagate(nextState);
        }

        if (nextState.day != this.state.day) {
            this.propagate(nextState);
        }
    }

    render(props) {
        return (
            <date-select {...props}>
                {this.renderSelects()}
            </date-select>
        );
    }

    renderSelects = () => {
        return (
            <fieldset className={this.props.class}>
                <legend>{this.props.group_label}</legend>
                <label htmlFor='month'>
                    <select
                        onChange={this.setMonth}
                        name='month'
                        required
                    >
                        <option selected disabled>month</option>
                        {this.state.months.map(month => (
                            <option value={dayjs().month(month).format('MM')}>
                                {dayjs().month(month).format('MMMM')}
                            </option>
                        ))}
                    </select>
                    <span className='error-msg' />
                </label>
                {this.renderDaySelect()}
                <label htmlFor='year'>
                    <select
                        onChange={this.setYear}
                        name='year'
                        required
                    >
                        <option selected disabled>year</option>
                        {this.state.years.map(year => (
                            <option value={year}>{year}</option>
                        ))}
                    </select>
                    <span className='error-msg' />
                </label>
            </fieldset>
        );
    }

    renderDaySelect = () => {
        if (!this.props.month_only) {
            return (
                <label htmlFor='day'>
                    <select
                        onChange={this.setDay}
                        name='day'
                        required
                    >
                        <option selected disabled>day</option>
                        {this.state.days.map(day => (
                            <option value={('0' + (day + 1)).substr(-2)}>{day + 1}</option>
                        ))}
                    </select>
                    <span className='error-msg' />
                </label>
            );
        }

        return null;
    }

    setMonth = (e) => {
        this.setState({ month: e.target.value });
    }

    setDay = (e) => {
        this.setState({ day: e.target.value });
    }

    setYear = (e) => {
        this.setState({ year: e.target.value });
    }

    getDays = (state) => {
        if (!this.props.month_only) {
            const year = state.year ? state.year : state.years[0];
            const month = state.month ? state.month : 1;
            const daysInMonth = dayjs(`${year}-${month}`, 'YYYY-MM').daysInMonth();
            const days = _.range(daysInMonth);

            this.setState({ days: days });
        }
    }

    propagate = (state) => {
        if (state.month && state.day && state.year && this.props.change) {
            this.props.change(
                this.props.name,
                dayjs(`${state.year}-${state.month}-${state.day}`).format('YYYY-MM-DD')
            );
        }
    }
}

export default connect()(Component);
