import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { inViewport } from '../../shared/util';
import { MediaQuery as MQ } from '../../shared/media-query';
import { v4 as uuidv4 } from 'uuid';
import _ from 'underscore';

class Component extends BaseComponent {
    constructor(props) {
        super(props);

        this.uuid = uuidv4();

        const tags = props.tags ? props.tags.split(',').map(tag => tag.trim()) : null;

        props.requirePromoMessage(this.uuid, props.placement, tags);

        this.scrollListener = _.throttle(this.trackViewPromotion, 100);

        this.state = {
            view_promotion_sent: false,
        };
    }

    shouldComponentUpdate(nextProps) {
        if (
            nextProps.messages != this.props.messages
            &&
            nextProps.messages[this.uuid]
            &&
            nextProps.messages[this.uuid] != this.props.messages[this.uuid]
        ) {
            return true;
        }

        if (nextProps.logged_in != this.props.logged_in) {
            return true;
        }

        if (nextProps.promoPageViews != this.props.promoPageViews) {
            return true;
        }

        if (nextProps.report_id != this.props.report_id) {
            return true;
        }

        return false;
    }

    componentDidUpdate() {
        this.trackViewPromotion();
    }

    render({ messages, placement, tags, mode, view_promotion, class: className }) {
        const doRender = placement == 'sidebar' && (MQ.isMobile() || MQ.isTabletPortrait()) ? false : true;

        if (messages && messages[this.uuid] && messages[this.uuid].message && doRender) {
            let message = messages[this.uuid].message.replace(/\r\n|\n|\r|\t/gm, '');

            message = this.messageTransform(message);

            window.addEventListener('scroll', this.scrollListener);

            return (
                <promo-message
                    key={this.uuid}
                    uuid={this.uuid}
                    dangerouslySetInnerHTML={{ __html: message }}
                    onClick={this.trackClick}
                    placement={placement}
                    tags={tags}
                    mode={mode}
                    className={className}
                    view_promotion={view_promotion}
                />
            );
        }

        return (
            <promo-message
                key={this.uuid}
                placement={placement}
                tags={tags}
                uuid={this.uuid}
                mode={mode}
                className={className}
                view_promotion={view_promotion}
            />
        );
    }

    messageTransform = (message) => {
        if ((this.props.placement == 'in-product' || this.props.placement == 'free-reading-upsell')
            && message.match(/\{report_ID\}/) && this.props.report_id
        ) {
            message = message.replace(/\{report_ID\}/g, this.props.report_id);
        } else if (this.props.placement == 'tarot-page' && message.match(/\{card/) || message.match(/\{deck/)) {
            const card = this.props.card || { name: 'The Fool', the_name: 'The Fool' };
            const deck = this.props.deck || {
                name: 'Universal Waite',
                stripped_name: 'Universal Waite',
                slug: 'universal-waite',
            };

            message = message.replace(/\{card.name\}/g, card.name);
            message = message.replace(/\{card.the_name\}/g, card.the_name);
            message = message.replace(/\{deck.name\}/g, deck.name);
            message = message.replace(/\{deck.stripped_name\}/g, deck.stripped_name);
            message = message.replace(/\{deck.slug\}/g, deck.slug);
        } else if (
            this.props.placement == 'text-vip-message'
            ||
            this.props.placement == 'text-catalog'
            ||
            this.props.placement == 'text-checkout'
        ) {
            message = `<p>${message}</p>`;
        }

        return message;
    }

    trackClick = () => {
        const category = this.props.placement + ' promo message';
        const label = this.props.messages[this.uuid].code;

        this.props.trackEvent(['Click', `Promo:${this.props.placement}-${label}`]);
        this.props.trackPromo([category, label]);
    }

    trackViewPromotion = () => {
        // When false, send event on pageload even if promos aren't visible
        // used for nav promos on astrology reports
        const check_visibility = !this.props.view_promotion || this.props.view_promotion != 'hidden';

        if (
            !this.state.view_promotion_sent
            &&
            this.props.messages
            &&
            this.props.messages[this.uuid]
            &&
            this.props.messages[this.uuid].code
            &&
            inViewport(`promo-message[uuid="${this.uuid}"]`, check_visibility)
        ) {
            window.removeEventListener('scroll', this.scrollListener);

            this.setState({
                view_promotion_sent: true,
            });

            this.props.trackViewPromotion({
                placement: this.props.placement,
                id: this.props.messages[this.uuid].code,
                name: `Promo:${this.props.placement}-${this.props.messages[this.uuid].code}`,
            });
        }
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.user && state.user.logged_in,
    messages: state['promo-message'],
    promoPageViews: state.cookie && state.cookie.promo_page_views,
    report_id: state.report && state.report.constants && state.report.constants.product_slug,
    deck: state.deck,
    card: state.card,
});

const mapDispatchToProps = (dispatch) => ({
    requirePromoMessage: (uuid, placement, tags) => dispatch({ type: 'promo-message:require', payload: {
        uuid: uuid,
        placement: placement,
        tags: tags,
    }}),
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    trackPromo: (payload) => dispatch({ type: 'promo-tracker:recordInternal', payload: payload }),
    trackViewPromotion: (payload) => dispatch({ type: 'ga:view_promotion', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
