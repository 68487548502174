import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';

export class Component extends BaseComponent {
    shouldComponentUpdate(nextProps) {
        if (nextProps.kc_sale_message && nextProps.kc_sale_message != this.props.kc_sale_message) {
            return true;
        }

        return false;
    }

    render() {
        return (
            <kc-sale-message>
                {this.renderMessage()}
            </kc-sale-message>
        );
    }

    renderMessage = () => {
        if (this.props.kc_sale_message) {
            if (this.props.kc_sale_message.promo) {
                const placement = this.props.kc_sale_message.promo.placement;
                const code = this.props.kc_sale_message.promo.code;

                this.props.trackViewPromotion({
                    placement: placement,
                    id: code,
                    name: `Promo:${placement}-${code}`,
                });
            }

            return (
                <p
                    className='kc-sale-msg'
                    dangerouslySetInnerHTML={{ __html: this.props.kc_sale_message.message }}
                />
            );
        }

        return null;
    }
}

const mapStateToProps = (state) => ({
    kc_sale_message: state.product.kc_sale_message,
});

const mapDispatchToProps = (dispatch) => ({
    trackViewPromotion: (payload) => dispatch({
        type: 'ga:view_promotion',
        payload: payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
