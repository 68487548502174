import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import JournalModal from './journal';
import LoginModal from './login';
import PasswordModal from './password';
import ProfileModal from './profile';
import SignupModal from './signup';
import SMSModal from './sms';
import VIPUnsubscribe from './vip-unsubscribe';

class Component extends BaseComponent {
    constructor() {
        super();
        this.dialog;
    }

    documentEscapeListener = (e) => {
        if (e.keyCode == 27 && this.props.route == 'password') {
            e.preventDefault();
            document.location.href = '/';
        }
    }

    escapeListener = (e) => {
        if (e.keyCode == 27) {
            e.preventDefault();
            this.close();
        }
    }

    clickListener = (e) => {
        if (e.target.tagName == 'DIALOG') {
            if (
                this.props.route != 'signup'
                ||
                confirm('Your signup is not complete. Are you sure you want to exit?')
            ) {
                this.close();
            }
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.documentEscapeListener);

        this.dialog.addEventListener('keydown', this.escapeListener);

        this.dialog.addEventListener('mousedown', this.clickListener);

        if (['journal', 'login', 'login-signup', 'password', 'signup', 'sms'].includes(this.props.route)) {
            this.dialog.showModal();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.documentEscapeListener);

        this.dialog.removeEventListener('keydown', this.escapeListener);

        this.dialog.removeEventListener('mousedown', this.clickListener);
    }

    shouldComponentUpdate(nextProps) {
        if (!nextProps.autoLogin && nextProps.route == 'password') {
            this.dialog.close();
            this.props.routeTo('');
            return false;
        }

        if (nextProps.route == '') {
            if ([
                'journal',
                'login',
                'login-signup',
                'profile',
                'signup',
                'sms',
                'vip-unsubscribe',
            ].includes(this.props.route)) {
                this.dialog.close();
            }
        }

        if (nextProps.route != this.props.route) {
            if (this.props.route == 'password' && nextProps.autoLogin) {
                this.props.routeTo('password');
                return false;
            }

            return true;
        }

        return false;
    }

    render() {
        return (
            <modal>
                <dialog ref={el => this.dialog = el}>
                    {this.renderChild()}
                </dialog>
            </modal>
        );
    }

    renderChild = () => {
        if (this.props.route) {
            let ChildModal = null;

            if (this.props.route == 'journal') {
                ChildModal = JournalModal;
            } else if (this.props.route == 'login' || this.props.route == 'login-signup') {
                ChildModal = LoginModal;
            } else if (this.props.route == 'password') {
                ChildModal = PasswordModal;
            } else if (this.props.route == 'profile') {
                ChildModal = ProfileModal;
            } else if (this.props.route == 'signup') {
                ChildModal = SignupModal;
            } else if (this.props.route == 'sms') {
                ChildModal = SMSModal;
            } else if (this.props.route == 'vip-unsubscribe') {
                ChildModal = VIPUnsubscribe;
            }

            if (ChildModal) {
                if (this.dialog && !this.dialog.open) {
                    window.hideSMVideoUnit();
                    this.dialog.showModal();
                }

                return (<ChildModal close={this.close} />);
            }
        }

        return null;
    }

    close = () => {
        let ga4_info = `SiteInteraction:${this.props.route.charAt(0).toUpperCase()}${this.props.route.slice(1)}`;

        if (this.props.route == 'password' && this.props.autoLogin) {
            document.location.href = '/';
            return false;
        } else if (this.props.route == 'sms') {
            this.props.updateProduct({
                pending: false,
            });
            ga4_info = 'SiteInteraction:SMS';
        } else if (this.props.route == 'vip-unsubscribe') {
            ga4_info = 'SiteInteraction:VIP-Unsubscribe';
        }

        this.props.trackEvent([
            'Close',
            ga4_info,
        ]);

        this.props.routeTo('');

        window.showSMVideoUnit();

        return true;
    }
}

const mapStateToProps = (state) => ({
    route: state.route,
    autoLogin: state.user && state.user.auto_login,
});

const mapDispatchToProps = (dispatch) => ({
    routeTo: (route) => dispatch({ type: 'route:to', payload: route }),
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    updateProduct: (payload) => dispatch({ type: 'product:update', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
