import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { MediaQuery as MQ } from '../../../shared/media-query';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

class Component extends BaseComponent {
    constructor(props) {
        super(props);

        props.trackEvent(['Open', 'SiteInteraction:VIP_Unsubscribe']);

        this.five_days_from_now = dayjs().add(5, 'day');

        this.in_5_days = props.next_billing_date
            && this.five_days_from_now.isSameOrAfter(props.next_billing_date);
    }

    render({ close }) {
        return (
            <vip-unsubscribe-modal>
                <dialog-header>
                    <button onClick={close}>×</button>
                    {this.renderHeader()}
                    {this.renderMessage()}
                </dialog-header>
                <dialog-content>
                    <row className='is-wrap'>
                        <column className='is-12 has-text-center'>
                            <a
                                className='button'
                                href='#'
                                onClick={(e) => {
                                    this.trackClick('SiteInteraction:VIP_RemainVIP');
                                    this.close(e);
                                }}
                            >
                                {this.keepText()}
                            </a>
                        </column>
                        <column className='is-12 has-text-center has-padding-10-top'>
                            <a href='#' onClick={this.submit}>
                                {this.unsubText()}
                            </a>
                            <form method='POST' ref={el => this.form = el}>
                                <input type='hidden' name='unsubscribe' value='1' />
                            </form>
                        </column>
                    </row>
                </dialog-content>
            </vip-unsubscribe-modal>
        );
    }

    renderHeader = () => {
        const word = this.in_5_days ? 'Reading' : 'Perks';

        return (
            <h1 className='fancy-title has-text-center'>
                Don't Lose Your<br />VIP {word}, {this.props.user.username}!
            </h1>
        );
    }

    renderMessage = () => {
        if (this.in_5_days) {
            const days = dayjs(this.props.next_billing_date, 'YYYY-MM-DD').diff(dayjs(), 'days') + 1;
            const day_text = days == 1 ? 'day' : 'days';

            if (MQ.isMobile()) {
                return (
                    <p className='has-text-center has-margin-0-bottom'>
                        You're only {days} {day_text} away<br />
                        from your FREE Tarot<br />
                        reading, are you sure you<br />
                        want to miss out on it?
                    </p>
                );
            }

            return (
                <p className='has-text-center has-margin-0-bottom'>
                    You're only {days} {day_text} away from your<br />
                    FREE Tarot reading, are you sure<br />
                    you want to miss out on it?
                </p>
            );
        }

        return (
            <p className='has-text-center has-margin-0-bottom'>
                Are you sure you want to miss out on all this?
                <br />
                <span className='has-text-size-14'>
                    &bull; Your FREE Monthly Tarot Reading
                    <br />
                    &bull; Your Personalized Daily Astrology
                    <br />
                    &bull; Your Ad-Free Experience
                </span>
            </p>
        );
    }

    keepText = () => {
        return this.in_5_days ? 'KEEP ME SUBSCRIBED' : 'I WANT TO REMAIN A VIP!';
    }

    unsubText = () => {
        return this.in_5_days ? 'I want to unsubscribe' : 'Please unsubscribe me';
    }

    close = (e) => {
        e.preventDefault();
        this.props.close();
    }

    trackClick = (info) => {
        this.props.trackEvent(['Click', info]);
    }

    submit = (e) => {
        e.preventDefault();
        this.trackClick('AccountSettings:VIP-Unsubscribe');
        this.form.submit();
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    next_billing_date: state.subscription && state.subscription.next_billing_date,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
