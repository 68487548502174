import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';

class Component extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = { submitting: false };

        if (!props.user || !props.user.logged_in || !props.slug) {
            props.close();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.submitting != this.state.submitting) {
            return true;
        }

        if (nextProps.error != this.props.error) {
            if (!nextProps.error) {
                this.setState({ submitting: false });
            }

            return true;
        }

        return false;
    }

    render({ close, user, slug }, { submitting }) {
        if (user && user.logged_in && slug) {
            return (
                <journal-modal>
                    <dialog-header>
                        <button onClick={close}>×</button>
                    </dialog-header>
                    <dialog-content>
                        <p className='has-text-center'>
                            Are you sure you want to<br className='is-visible-mobile' />
                            <span className='is-hidden-mobile'> </span>remove this reading?
                        </p>
                        <p className='has-text-center'>This action cannot be undone.</p>
                        <div className='has-text-center has-margin-15-bottom'>
                            <a
                                href='#'
                                className={`button button-warning ${submitting ? 'disabled' : ''}`}
                                onClick={this.remove}
                            >
                                {submitting ? 'SUBMITTING…' : 'REMOVE'}
                            </a>
                        </div>
                        {this.renderError()}
                        <div className='has-text-center'>
                            <a href='#' onClick={(e) => { e.preventDefault(); close();}}>Cancel</a>
                        </div>
                    </dialog-content>
                </journal-modal>
            );
        }

        return null;
    }

    renderError = () => {
        if (this.props.error) {
            return (<p className='is-error'>{this.props.error}</p>);
        }

        return null;
    }

    remove = (e) => {
        e.preventDefault();
        this.setState({ submitting: true });

        this.props.trackEvent(['Click', 'Journal:Remove']);

        this.props.deleteJournal({ slug: this.props.slug });
    }
}

const mapStateToProps = (state) => ({
    route: state.route,
    user: state.user,
    slug: state.journal && state.journal.slug,
    error: state.journal && state.journal.error,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    deleteJournal: (payload) => dispatch({ type: 'journal:delete', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
