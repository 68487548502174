import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import Zodiac from './types/zodiac';
import Chinese from './types/chinese';

class Component extends BaseComponent {
    shouldComponentUpdate(nextProps) {
        if (nextProps.hash && nextProps.path != this.props.path) {
            return true;
        }

        if (nextProps.path && nextProps.hash != this.props.hash) {
            return true;
        }

        if (nextProps.submitting != this.props.submitting) {
            return true;
        }

        return false;
    }

    render({ type, slant, path, hash, submitting, button_text, sign_one }) {
        let ChooserType = null;
        if (type == 'zodiac') {
            ChooserType = Zodiac;
        } else if (type == 'chinese') {
            ChooserType = Chinese;
        }

        if (ChooserType) {
            return (
                <compatibility-chooser type={type} slant={slant} button_text={button_text} sign_one={sign_one}>
                    <span>
                        <ChooserType slant={slant} sign_one={sign_one} />
                    </span>
                    <div className='button-wrapper'>
                        <button
                            className={`button-warning${path && hash && !submitting ? '' : ' disabled'}`}
                            onClick={this.getReport}
                        >
                            {this.renderButtonText()}
                        </button>
                    </div>
                </compatibility-chooser>
            );
        }

        return (<compatibility-chooser type={type} slant={slant} sign_one={sign_one} button_text={button_text} />);
    }

    renderButtonText = () => {
        if (this.props.submitting) {
            return 'submitting…';
        }

        return this.props.button_text || 'GET YOUR REPORT';
    }

    getReport = () => {
        if (this.props.path && this.props.hash) {
            this.props.report(this.state);
        }
    }
}

const mapStateToProps = (state) => ({
    path: state['compatibility-chooser'] && state['compatibility-chooser'].path,
    hash: state['compatibility-chooser'] && state['compatibility-chooser'].hash,
    submitting: state['compatibility-chooser'] && state['compatibility-chooser'].submitting,
});

const mapDispatchToProps = (dispatch) => ({
    report: (payload) => dispatch({ type: 'compatibility-chooser:report', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
