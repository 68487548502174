import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import DateSelect from '../../../../shared/form/date-select';

class Component extends BaseComponent {
    componentDidMount() {
        this.props.set({
            type: 'chinese',
            slant: this.props.slant,
            base_url: '/astrology/compatibility/chinese/',
        });
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.path != this.props.path) {
            return true;
        }

        return false;
    }

    render({ slant, path }) {
        const className = path && path.name ? 'chosen' : null;
        return (
            <compatibility-chooser-chinese slant={slant}>
                <DateSelect
                    group_label='YOUR BIRTH DATE'
                    name='path'
                    direction='past'
                    product='Chinese Compatibility'
                    className='chosen'
                    change={this.change}
                />
                <DateSelect
                    group_label='THEIR BIRTH DATE'
                    name='hash'
                    direction='past'
                    product='Chinese Compatibility'
                    className={className}
                    change={this.change}
                />
            </compatibility-chooser-chinese>
        );

    }

    change = (which, value) => {
        this.props.setSign({
            which: which,
            date: value,
        });
    }
}

const mapStateToProps = (state) => ({
    path: state['compatibility-chooser'] && state['compatibility-chooser'].path,
    hash: state['compatibility-chooser'] && state['compatibility-chooser'].hash,
});

const mapDispatchToProps = (dispatch) => ({
    set: (payload) => dispatch({ type: 'compatibility-chooser:update', payload: payload }),
    setSign: (payload) => dispatch({ type: 'compatibility-chooser:setChineseSign', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
