import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { months } from '../../shared/util';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { date_to_sign_name } from '../../shared/util';

dayjs.extend(customParseFormat);

class Component extends BaseComponent {
    constructor(props) {
        super(props);

        this.innerHTML = props.innerHTML;
        this.yearArr = [];
        for (let y = dayjs().year(); y >= dayjs().subtract(100, 'year').year(); y--) {
            this.yearArr.push(y);
        }

        this.state = {
            isOpen: false,
            mode: 'date',
            displayedDate: props.date ? dayjs(props.date, 'YYYY-MM-DD') : dayjs().startOf('day'),
            selectedDate: props.date ? dayjs(props.date, 'YYYY-MM-DD') : null,
            mindate: props.mindate ? dayjs(props.mindate, 'YYYY-MM-DD') : null,
            maxdate: props.maxdate ? dayjs(props.maxdate, 'YYYY-MM-DD') : null,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.isOpen && !this.state.isOpen) { //Opening
            this.props.trackEvent([
                'Open',
                'SiteInteraction:DatepickerCalendar',
            ]);

            return true;
        }

        if (!nextState.isOpen && this.state.isOpen) { //Closing
            this.props.trackEvent([
                'Close',
                'SiteInteraction:DatepickerCalendar',
            ]);

            return true;
        }

        if (nextState.mode != this.state.mode) {
            return true;
        }

        if (nextState.selectedDate != this.state.selectedDate) {
            return true;
        }

        if (!nextState.displayedDate.isSame(this.state.displayedDate)) {
            return true;
        }

        return false;
    }

    render({ logged_in, page, url, mindate, maxdate, date, autosubmit }, { isOpen, displayedDate }) {
        if (isOpen) {
            return (
                <date-picker
                    page={page}
                    url={url}
                    mindate={mindate}
                    maxdate={maxdate}
                    date={date}
                    autosubmit={autosubmit}
                >
                    {this.renderIcon()}
                    <date-picker-underlay onClick={this.closeDatePicker} />
                    <date-picker-body>
                        <date-picker-header>
                            {this.renderCloseX()}
                            <p className='has-text-center has-text-boulder has-text-size-12 has-margin-0'>
                                Choose a Date:
                            </p>
                        </date-picker-header>
                        <date-picker-nav>
                            <row className='is-aligned-center'>
                                <column className='is-2 has-text-center'>
                                    <icon className='left' onClick={this.displayPreviousMonth} />
                                </column>
                                <column className='is-8 has-text-center'>
                                    <h2>
                                        <a onClick={() => this.setMode('month')}>
                                            {displayedDate.format('MMMM')}
                                        </a>
                                        &nbsp;
                                        <a onClick={() => this.setMode('year')}>
                                            {displayedDate.format('YYYY')}
                                        </a>
                                    </h2>
                                </column>
                                <column className='is-2 has-text-center'>
                                    <icon className='right' onClick={this.displayNextMonth} />
                                </column>
                            </row>
                        </date-picker-nav>
                        {this.renderYearMode()}
                        {this.renderMonthMode()}
                        {this.renderDateMode()}
                    </date-picker-body>
                </date-picker>
            );
        }

        if (['number', 'hexagram'].includes(page) && !logged_in) {
            return (
                <date-picker
                    page={page}
                    url={url}
                    mindate={mindate}
                    maxdate={maxdate}
                    date={date}
                    autosubmit={autosubmit}
                />
            );
        }

        return (
            <date-picker
                page={page}
                url={url}
                mindate={mindate}
                maxdate={maxdate}
                date={date}
                autosubmit={autosubmit}
            >
                {this.renderIcon()}
            </date-picker>
        );
    }

    renderIcon = () => {
        if (this.innerHTML) {
            return (
                <span
                    onClick={this.openDatePicker}
                    dangerouslySetInnerHTML={{ __html: this.innerHTML }}
                />
            );
        }

        return (<date-picker-icon onClick={this.openDatePicker} />);
    }

    renderCloseX = () => {
        if (this.props.autosubmit) {
            return (<date-picker-close onClick={this.closeDatePicker}>×</date-picker-close>);
        }

        return null;
    }

    renderYearMode = () => {
        if (this.state.mode == 'year') {
            return (
                <date-picker-scroll>
                    {this.yearArr.map(year => {
                        return (
                            <h2 className='has-text-center has-margin-10'>
                                <a onClick={() => this.setYear(year)}>
                                    {year}
                                </a>
                            </h2>
                        );
                    })}
                </date-picker-scroll>
            );
        }

        return null;
    }

    renderMonthMode = () => {
        if (this.state.mode == 'month') {
            return (
                <date-picker-scroll>
                    {Object.keys(months).map(key => {
                        return (
                            <h2 className='has-text-center has-margin-10'>
                                <a onClick={() => this.setMonth(key)}>
                                    {months[key]}
                                </a>
                            </h2>
                        );
                    })}
                </date-picker-scroll>
            );
        }

        return null;
    }

    renderDateMode = () => {
        if (this.state.mode == 'date') {
            return (
                <date-picker-scroll>
                    <date-picker-calendar>
                        <date-picker-day-names>
                            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => <span>{day}</span>)}
                        </date-picker-day-names>
                        <date-picker-days>
                            {this.renderDays()}
                        </date-picker-days>
                    </date-picker-calendar>
                    {this.renderButtons()}
                </date-picker-scroll>
            );
        }

        return null;
    }

    renderDays = () => {
        return this.getDaysInMonth().map(day => {
            if (day) {
                const selected = this.state.selectedDate ? this.state.selectedDate.isSame(day) : false;
                const today = day.format('MM-DD-YYYY') == dayjs().format('MM-DD-YYYY');
                const disabled = (
                    (this.state.mindate && day.isBefore(this.state.mindate))
                    ||
                    (this.state.maxdate && day.isAfter(this.state.maxdate))
                    ||
                    (this.props.page == 'weekly-horoscope' && dayjs(day).day() != 1)
                )
                    ? true
                    : false;

                /* eslint-disable react/no-unknown-property */
                /* unknown-property:  date */
                return (
                    <span
                        className={`${today ? 'today ' : ''}${selected ? 'selected' : ''}`}
                        disabled={disabled}
                        date={day.format('YYYY-MM-DD')}
                        onClick={disabled ? null : this.setSelectedDate}
                    >
                        {day.format('D')}
                    </span>
                );
                /* eslint-enable react/no-unknown-property */
            }

            return (<span disabled='true' />);
        });
    }

    renderButtons = () => {
        if (!this.props.autosubmit) {
            return (
                <date-picker-buttons>
                    <button onClick={this.closeDatePicker}>CANCEL</button>
                    <button onClick={() => this.submitDatePicker(this.state.selectedDate)}>OK</button>
                </date-picker-buttons>
            );
        }

        return (<date-picker-buttons />);
    }

    getDaysInMonth = () => {
        let daysInMonth = this.state.displayedDate.daysInMonth();
        let days = [];

        const firstWeekday = dayjs(`1-${this.state.displayedDate.format('M-YYYY')}`, 'D-M-YYYY').day();

        if (firstWeekday > 0) {
            for (let d = 0; d < firstWeekday; d++) {
                days.push(false);
            }
        }

        for (let d = 1; d <= daysInMonth; d++) {
            days.push(dayjs(
                this.state.displayedDate.format('M') + '-' + d + '-' + this.state.displayedDate.format('YYYY'),
                'M-D-YYYY'
            ));
        }

        return days;
    }

    setMode = (mode) => {
        this.setState({ mode: mode == this.state.mode ? 'date' : mode });
    }

    displayPreviousMonth = () => {
        const displayedDate = dayjs(this.state.displayedDate.format('YYYY-MM-DD'), 'YYYY-MM-DD').subtract(1, 'month');
        this.setState({
            displayedDate: displayedDate,
            mode: 'date',
        });
    }

    displayNextMonth = () => {
        const displayedDate = dayjs(this.state.displayedDate.format('YYYY-MM-DD'), 'YYYY-MM-DD').add(1, 'month');
        this.setState({
            displayedDate: displayedDate,
            mode: 'date',
        });
    }

    setYear = (year) => {
        const month = this.state.displayedDate.format('MM');
        this.setState({
            displayedDate: dayjs(`${year}-${month}-01`),
            mode: 'date',
        });
    }

    setMonth = (month) => {
        const year = this.state.displayedDate.format('YYYY');
        this.setState({
            displayedDate: dayjs(`${year}-${month.toString().padStart(2, 0)}-01`),
            mode: 'date',
        });
    }

    setSelectedDate = (e) => {
        const selectedDate = dayjs(e.target.getAttribute('date'), 'YYYY-MM-DD');

        if (this.props.autosubmit) {
            this.submitDatePicker(selectedDate);
        } else {
            this.setState({
                selectedDate: dayjs(e.target.getAttribute('date'), 'YYYY-MM-DD'),
            });
        }
    }

    openDatePicker = () => {
        this.setState({ isOpen: true });
    }

    closeDatePicker = () => {
        this.setState({
            isOpen: false,
            yearMode: false,
        });
    }

    submitDatePicker = (date) => {
        if (date) {
            this.props.trackEvent([
                'Click',
                `SiteInteraction:DatepickerCalendar-${date.format('DD-MMM-YYYY')}-Select`,
            ]);

            if (this.props.page && this.props.page == 'birthday') {
                window.location = this.props.url.replace(/:date/g,
                    `${date_to_sign_name(date)}/${date.format('MM-DD')}`);
            } else {
                window.location = this.props.url.replace(/:date/g, date.format('YYYY-MM-DD'));
            }
        }
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.user && state.user.logged_in,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
