import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';

class Component extends BaseComponent {
    constructor(props) {
        super(props);
        this.innerHTML = props.innerHTML;
        delete(props.innerHTML);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.logged_in != this.props.logged_in) {
            return true;
        }

        return false;
    }

    render({ innerHTML, logged_in }) {
        if (innerHTML) {
            delete(this.props.innerHTML); //eslint-disable-line tarot/no-direct-props-access-in-render
        }

        if (logged_in) {
            return (
                <logged-in className='shown'>
                    <span dangerouslySetInnerHTML={{ __html: this.innerHTML }} />
                </logged-in>
            );
        }

        return (<logged-in {...this.props} />); //eslint-disable-line tarot/no-direct-props-access-in-render
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.user && state.user.logged_in,
});

export default connect(mapStateToProps)(Component);
