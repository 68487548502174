import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { Suspense, lazy } from 'preact/compat';
const SelectGroup = lazy(
    () => import(/* webpackChunkName: "select-group" */ '../../shared/form/select-group')
);

class Component extends BaseComponent {
    constructor() {
        super();

        const months = {
            1: 'January',
            2: 'February',
            3: 'March',
            4: 'April',
            5: 'May',
            6: 'June',
            7: 'July',
            8: 'August',
            9: 'September',
            10: 'October',
            11: 'November',
            12: 'December',
        };

        const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
            19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

        let years = [];
        for (let y = 0; y <= 101; y++) {
            years.push(dayjs().subtract(y, 'year').format('YYYY'));
        }

        this.state = {
            formError: false,
            month: {
                value: null,
                error: null,
            },
            months: months,
            day: {
                value: null,
                error: null,
            },
            days: days,
            year: {
                value: null,
                error: null,
            },
            years: years,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState != this.state) {
            return true;
        }

        return false;
    }

    render(props, { month, months, day, days, year, years }) {
        return (
            <chinese-zodiac-sign-datepicker>
                <div className='has-text-center-mobile' id='chinese-calculator'>
                    <h3 className='has-margin-25-bottom'>Calculate Your Chinese Sign:</h3>
                    <h4 className='has-margin-15-bottom'>Enter your birthdate:</h4>
                </div>
                <form onSubmit={this.submit}>
                    <row className='is-wrap'>
                        <column className='is-12-mobile is-4-tablet is-3-desktop has-padding-5-right'>
                            <Suspense fallback={<div className='skeleton' />}>
                                <SelectGroup
                                    name='month'
                                    value={month.value}
                                    placeholder='MONTH'
                                    options={months}
                                    error={month.error}
                                    onChange={this.setFormValues}
                                />
                            </Suspense>
                        </column>
                        <column className='is-12-mobile is-4-tablet is-3-desktop has-padding-5-right'>
                            <Suspense fallback={<div className='skeleton' />}>
                                <SelectGroup
                                    name='day'
                                    value={day.value}
                                    placeholder='DAY'
                                    options={days}
                                    error={day.error}
                                    onChange={this.setFormValues}
                                />
                            </Suspense>
                        </column>
                        <column className='is-12-mobile is-4-tablet is-3-desktop has-padding-5-right'>
                            <Suspense fallback={<div className='skeleton' />}>
                                <SelectGroup
                                    name='year'
                                    value={year.value}
                                    placeholder='YEAR'
                                    options={years}
                                    error={year.error}
                                    onChange={this.setFormValues}
                                />
                            </Suspense>
                        </column>
                        <column className='is-12-mobile is-4-tablet is-3-desktop'>
                            <button className='has-margin-10-top button'>Find Out Now</button>
                        </column>
                    </row>
                </form>
            </chinese-zodiac-sign-datepicker>
        );
    }

    setFormValues = (e) => {
        let state = [];

        if ((e.type == 'keyup' && e.key == 'Enter') || e.type == 'change') {
            state[e.target.name] = {
                value: e.target.value,
                error: false,
            };
        }

        this.setState(state);
    }

    submit = (e) => {
        e.preventDefault();

        this.setState({ formError: false });

        const state = this.state;

        if (!this.state.month.value) {
            state.month.error = 'This field is required';
            state.formError = true;
        }

        if (!this.state.day.value) {
            state.day.error = 'This field is required';
            state.formError = true;
        }

        if (!this.state.year.value) {
            state.year.error = 'This field is required';
            state.formError = true;
        }

        this.setState(state);

        if (!this.state.formError) {
            this.props.getSign({
                year: this.state.year.value,
                month: this.state.month.value,
                day: this.state.day.value,
            });
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    getSign: payload => dispatch({
        type: 'zodiac:send-to-chinese-horoscope',
        payload: payload,
    }),
});

export default connect(null, mapDispatchToProps)(Component);
