import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { smoothScrollToElement } from '../../shared/scroll';
import { Suspense, lazy } from 'preact/compat';
const InputGroup = lazy(
    () => import(/* webpackChunkName: "input-group" */ '../../shared/form/input-group')
);
const SelectGroup = lazy(
    () => import(/* webpackChunkName: "select-group" */ '../../shared/form/select-group')
);
import TextareaGroup from '../../shared/form/textarea-group';

class Component extends BaseComponent {
    constructor() {
        super();

        this.error = null;

        this.subjects = [
            'Please unsubscribe me from emails',
            'I\'d like to close my account',
            'I need to reset my password',
            'I need to make updates to my account',
            'I can\'t find the reading/report I purchased',
            'I have a question regarding a purchase',
            'I have a question about Karma Coins',
            'I need to update my purchased report with the correct birth info',
            'I have feedback about your ads',
            'Unsatisfied commentary',
            'Nothing! I just wanted to say thanks',
            'Other (Please tell us more)',
        ];

        this.state = {
            subject: {
                value: null,
                error: null,
            },
            name: {
                value: null,
                error: null,
            },
            email: {
                value: null,
                error: null,
            },
            phone: {
                value: null,
                error: null,
            },
            message: {
                value: null,
                error: null,
            },
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!this.props.route && nextProps.route == 'service') {
            smoothScrollToElement(document.getElementById('service'));
        }

        if (nextState != this.state) {
            this.props.updateForm({ data: nextState });
            return true;
        }

        if (nextProps.submitting != this.props.submitting) {
            return true;
        }

        if (nextProps.submitted != this.props.submitted) {
            return true;
        }

        if (nextProps.route != this.props.route) {
            return true;
        }

        return false;
    }

    render({ submitted, submitting }, { email, message, name, phone, subject }) {
        if (!submitted) {
            return (
                <service-contact-form>
                    <h1>Contact Us</h1>
                    <p>
                        We're always happy to hear from you! Have a question? Want to give us feedback?  Please fill out
                        the form below, and our support team will respond to you as quickly as possible.
                    </p>
                    <p>
                        You may be able to save some time by checking out our&nbsp;
                        <a href='/service/frequently-asked-questions'>Frequently Asked Questions</a> page, which
                        provides answers to the most common questions we've received from members just like you!
                    </p>
                    <row className='is-wrap'>
                        <column className='is-12'>
                            <Suspense fallback={<div className='skeleton' />}>
                                <SelectGroup
                                    name='subject'
                                    label='Subject'
                                    placeholder={subject.value ? '' : 'SELECT YOUR CONCERN'}
                                    value={subject.value}
                                    options={this.subjects}
                                    error={subject.error}
                                    onChange={this.setFormValues}
                                />
                            </Suspense>
                        </column>
                        <column className='is-12'>
                            <Suspense fallback={<div className='skeleton' />}>
                                <InputGroup
                                    name='name'
                                    label='Name'
                                    placeholder='ENTER NAME'
                                    type='text'
                                    value={name.value}
                                    error={name.error}
                                    onInput={this.setFormValues}
                                />
                            </Suspense>
                        </column>
                        <column className='is-12'>
                            <Suspense fallback={<div className='skeleton' />}>
                                <InputGroup
                                    name='email'
                                    label='Email'
                                    placeholder='ENTER YOUR EMAIL'
                                    type='email'
                                    value={email.value}
                                    error={email.error}
                                    onInput={this.setFormValues}
                                />
                            </Suspense>
                        </column>
                        <column className='is-5 is-12-mobile'>
                            <Suspense fallback={<div className='skeleton' />}>
                                <InputGroup
                                    name='phone'
                                    label='Phone'
                                    placeholder='+[x] xxx-xxx-xxxx'
                                    type='tel'
                                    value={phone.value}
                                    error={phone.error}
                                    onInput={this.setFormValues}
                                />
                            </Suspense>
                        </column>
                        <column className='is-12'>
                            <p>
                                To help us better serve you, please provide us with as many details as possible about
                                the reason you're contacting us.
                            </p>
                        </column>
                        <column className='is-12'>
                            <TextareaGroup
                                name='message'
                                label='Message'
                                placeholder='TYPE YOUR MESSAGE'
                                value={message.value}
                                error={message.error}
                                onInput={this.setFormValues}
                            />
                        </column>
                        <column className='is-12 has-text-center has-padding-20-top'>
                            <button
                                className={`button ${submitting ? 'disabled' : ''}`}
                                disabled={submitting}
                                onClick={this.submit}
                            >
                                {submitting ? 'SUBMITTING...' : 'SUBMIT'}
                            </button>
                            {this.renderFormError()}
                        </column>
                    </row>
                </service-contact-form>
            );
        }

        /* eslint-disable react/jsx-closing-tag-location */
        return (
            <service-contact-form>
                <h1>Success!</h1>
                <h3>Your message has been sent.</h3>
                <p>
                    We appreciate you reaching out to us and look forward to serving you! We respond to all messages
                    in the order they are received. Standard response time is less than 24 hours during business
                    hours, while weekends and holidays could cause delays.
                </p>
                <p>
                    Don't forget to read through our <a href='/service/frequently-asked-questions'>Frequently Asked
                    Questions</a> page, which may be able to instantly answer any questions you have.
                </p>
                <p>
                    Here are some things we think you might be interested in while you wait for our prompt reply:
                    <br />
                    <br />
                    <a href='/daily-horoscope'>Your Daily Horoscope</a><br />
                    <a href='/daily-love-horoscope'>Your Daily Love Horoscope</a><br />
                    <a href='/astrology/weekly-love-horoscopes'>Your Weekly Love Horoscope</a>
                </p>
            </service-contact-form>
        );
        /* eslint-enable react/jsx-closing-tag-location */
    }

    setFormValues = (e) => {
        let state = this.state;

        state[e.target.name] = {
            value: e.target.value,
            error: null,
        };

        this.setState(state);
    }

    renderFormError = () => {
        if (this.error) {
            return (
                <p className='is-error is-form-error'>Please check required fields above</p>
            );
        }

        return null;
    }

    submit = () => {
        this.error = null;
        let state = this.state;

        Object.keys(state).map(key => {
            if (key != 'phone' && !state[key].value) {
                state[key].error = 'This field is required';
                this.error = true;
            }

            /* eslint-disable max-len,no-useless-escape */
            const email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            /* eslint-enable max-len,no-useless-escape */

            if (!email_regex.test(state.email.value)) {
                state.email.error = 'Please enter a valid email address';
                this.error = true;
            }
        });

        this.setState(state);

        if (!this.error) {
            this.props.submit(state);
        }
    }
}

const mapStateToProps = (state) => ({
    submitting: state.product && state.product.submitting,
    submitted: state.product && state.product.submitted,
    route: state.route,
});

const mapDispatchToProps = (dispatch) => ({
    updateForm: (payload) => dispatch({ type: 'form:update', payload: payload }),
    submit: (payload) => dispatch({ type: 'service:contact-form-submit', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
