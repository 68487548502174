import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { inViewport } from '../../shared/util';
import _ from 'underscore';
const tid = 'MQNLOFW5';

class Component extends BaseComponent {
    constructor(props) {
        super(props);

        props.requirePsychics();

        this.keen_url = new URL('https://www.keen.com/landingpage/psychic-readings');
        this.keen_url.searchParams.set('u', tid);
        this.keen_url.searchParams.set('cat', '195');
        this.keen_url.searchParams.set('d', 'p');
        this.keen_url.searchParams.set('t', 'singleoffer104alt');
        this.keen_url.searchParams.set('utm_source', 'tarotcom');
        this.keen_url.searchParams.set('utm_medium', 'b2b');
        this.keen_url.searchParams.set('utm_campaign', 'general');
        this.keen_url.searchParams.set('utm_term', 'advisorlistLP');
        this.keen_url.searchParams.set('utm_content', '5min1');

        this.scrollListener = _.throttle(this.trackViewPromotion, 100);

        this.state = {
            view_promotion_sent: false,
        };
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.psychics != this.props.psychics) {
            return true;
        }

        return false;
    }

    componentDidUpdate() {
        this.trackViewPromotion();
    }

    render({ psychics }) {
        if (psychics && Object.keys(psychics).length) {
            window.addEventListener('scroll', this.scrollListener);

            return (
                <keen-slider>
                    <row className='is-wrap is-aligned-center has-margin-20-bottom has-margin-10-left-mobile'>
                        <column className='is-8 is-12-mobile'>
                            <h2 className='has-margin-10-bottom has-weight-400'>Get Psychic Advice Now</h2>
                        </column>
                        <column className='is-12-mobile has-padding-35-right'>
                            <p>
                                <a
                                    href={this.keen_url}
                                    target='_blank'
                                    rel='sponsored noopener noreferrer'
                                >
                                    Powered by Keen
                                </a>
                            </p>
                        </column>
                    </row>
                    <row className='is-wrap is-justified-around'>
                        {Object.keys(psychics).map(key => this.renderPsychic(psychics[key], key))}
                    </row>
                </keen-slider>
            );
        }

        return (<keen-slider />);
    }

    renderPsychic = (psychic, key) => {
        const psychic_url = new URL(`https://www.keen.com${psychic.detailsUrl}`);
        psychic_url.searchParams.set('tid', tid);
        psychic_url.searchParams.set('utm_source', 'tarotcom');
        psychic_url.searchParams.set('utm_medium', 'b2b');
        psychic_url.searchParams.set('utm_campaign', 'general');
        psychic_url.searchParams.set('utm_term', 'advisorlistLP');
        psychic_url.searchParams.set('utm_content', '5min1');

        return (
            <column className='is-6 is-12-mobile has-margin-10-top-mobile'>
                <a
                    className='keen-listing-container'
                    key={key}
                    href={psychic_url}
                    target='_blank'
                    rel='noopener sponsored noreferrer'
                    onClick={() => this.props.trackEvent([
                        'Click',
                        'Psychics_Slider:KeenAPI',
                    ])}
                >
                    <div className='keen-listing-price-container'>
                        <div className='offer-text'>
                        5 minutes for $1
                        </div>
                        <div className='listing-price'>
                            {psychic.serviceRate}/min
                        </div>
                    </div>
                    <row className='is-wrap is-aligned-center has-margin-10-bottom-mobile'>
                        <column className='is-6 is-flex is-justified-center'>
                            <div className='keen-image-container'>
                                <img
                                    src={psychic.picture.size500Version
                                        ? psychic.picture.size500Version.url
                                        : psychic.pictureURL}
                                    alt={psychic.speakerName}
                                    loading='lazy'
                                    className='keen-psychic'
                                    width='165'
                                    height='165'
                                />
                                <div className='dot' />
                            </div>
                        </column>
                        <column className='is-6 has-padding-5-left has-padding-20-left-mobile
                            has-padding-5-right has-padding-15-top'
                        >
                            <p className='psychic-name'>
                                <span>{psychic.speakerName}</span>
                            </p>
                            <p className='is-h5'>
                                <span>{psychic.averageRating}</span>
                                {[0, 1, 2, 3, 4].map(() => (
                                    <img
                                        src='https://gfx.tarot.com/images/daily-pages/daily-icons/keen-star.svg'
                                        width='auto'
                                        height='auto'
                                        loading='lazy'
                                        className='star'
                                        alt='Keen Psychic Rating Stars'
                                    />
                                ))}
                            </p>
                            <p className='is-h6 has-margin-15-left has-margin-10-bottom'>
                                {psychic.ratingCount.toLocaleString()} REVIEWS
                            </p>
                            <button className='is-pill'>CHAT NOW</button>
                        </column>
                    </row>
                </a>
            </column>
        );
    }

    trackViewPromotion = () => {
        if (
            !this.state.view_promotion_sent
            &&
            this.props.psychics
            &&
            inViewport('keen-slider', true)
        ) {
            window.removeEventListener('scroll', this.scrollListener);

            this.setState({
                view_promotion_sent: true,
            });

            this.props.trackViewPromotion({
                placement: 'keen-slider',
                id: 'Keen-API',
                name: 'Psychics_Slider:KeenAPI',
            });
        }
    }
}

const mapStateToProps = (state) => ({
    psychics: state.keen,
});

const mapDispatchToProps = (dispatch) => ({
    requirePsychics: () => dispatch({
        type: 'keen:init',
        payload: {
            tid: tid,
            size: 2,
        },
    }),
    trackEvent: (payload) => dispatch({
        type: 'ga:event',
        payload: payload,
    }),
    trackViewPromotion: (payload) => dispatch({
        type: 'ga:view_promotion',
        payload: payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
