import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';

class Component extends BaseComponent {
    componentDidMount() {
        if (this.props.autoLogin) {
            this.props.routeTo('password');
        }
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (<auto-login-check />);
    }
}

const mapStateToProps = (state) => ({
    autoLogin: state.user && state.user.auto_login,
});

const mapDispatchToProps = (dispatch) => ({
    routeTo: (hash) => dispatch({ type: 'route:to', payload: hash }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);