import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { Suspense, lazy } from 'preact/compat';
const CheckboxGroup = lazy(
    () => import(/* webpackChunkName: "checkbox-group" */ '../../../shared/form/checkbox-group')
);

class Component extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            agree: {
                checked: false,
                error: null,
            },
            error: false,
            telephone: {
                value: null,
                error: null,
            },
            phoneInputLoaded: false,
        };

        this.PhoneInput = null;
    }

    componentDidMount() {
        // dynamic loading of vendor-sms.js (react-phone-input-2).
        // leave the webpackChunkName empty if you are dynamically loading an existing entry bundle.
        import(/* webpackChunkName: "" */ 'react-phone-input-2')
            .then((PhoneInput) => {
                this.PhoneInput = PhoneInput.default;
                this.setState({
                    phoneInputLoaded: true,
                });
            })
            .catch(() => {});

        this.props.trackEvent(['Open', 'SiteInteraction:SMS']);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.telephone.value != this.state.telephone.value) {
            return true;
        }

        if (nextState.telephone.error != this.state.telephone.error) {
            return true;
        }

        if (nextState.agree.checked != this.state.agree.checked) {
            return true;
        }

        if (nextState.agree.error != this.state.agree.error) {
            return true;
        }

        if (nextState.error != this.state.error) {
            return true;
        }

        if (nextState.phoneInputLoaded != this.state.phoneInputLoaded) {
            return true;
        }

        if (nextProps.view != this.props.view) {
            return true;
        }

        return false;
    }

    render({ close, view, optInError }, { telephone, agree, phoneInputLoaded }) {
        const PhoneInput = this.PhoneInput;

        if ((!view || view == 1) && phoneInputLoaded) {
            /* eslint-disable-next-line max-len */
            /* eslint-disable react/jsx-closing-bracket-location,react/jsx-closing-tag-location,react/jsx-first-prop-new-line */
            // Disabled to preserve inline links
            return (
                <sms-modal>
                    <dialog-header>
                        <button onClick={close}>×</button>
                        {this.renderHeading()}
                        <p className='has-text-center has-margin-0-bottom'>
                            Yes! Sign me up for exclusive offers and supportive insight
                        </p>
                    </dialog-header>
                    <dialog-content>
                        <row className='is-wrap has-margin-15-top'>
                            <column className='is-12'>
                                <label>Enter Your <b>Mobile Number</b> (US Only)</label>
                                {phoneInputLoaded &&
                                    <PhoneInput
                                        country='us'
                                        onlyCountries={['us']}
                                        onChange={phone => this.setTelephone(phone)}
                                        placeholder='+1 (999) 555-1234'
                                        value={telephone.value}
                                        containerClass={telephone.error || optInError ? 'has-error' : null}
                                    />}
                            </column>
                            {this.renderPhoneError()}
                            {this.renderOptInError()}
                        </row>
                        <row className='has-margin-15-top'>
                            <column className='is-1 is-2-mobile'>
                                <Suspense fallback={<div className='skeleton' />}>
                                    <CheckboxGroup
                                        name='agree'
                                        checked={agree.checked}
                                        onChange={this.setAgree}
                                    />
                                </Suspense>
                            </column>
                            <column className='is-11 is-10-mobile'>
                                <p className='has-text-size-12 has-line-height-19'>
                                    By checking this box and clicking SUBMIT NUMBER, you agree to receive periodic
                                    mobile alerts from Tarot.com. Msg & data rates may apply. Text STOP to stop
                                    receiving messages. Text HELP for more information. <a href='/terms-of-use'
                                        target='_blank'>Terms & Conditions</a> | <a href='/privacy-policy'
                                        target='_blank'>Privacy Policy</a>
                                </p>
                                <p className='has-text-size-12 has-line-height-19'>Offer is good for one reading</p>
                                {this.renderAgreeError()}
                            </column>
                        </row>
                        <row className='has-margin-20-top'>
                            <column className='is-12 has-text-center'>
                                <a
                                    href='#'
                                    className='sms-footer-link'
                                    onClick={(e) => {
                                        this.trackClick('Modal_SMS:Back');
                                        this.close(e);
                                    }}
                                >
                                    Back to what<br />I was doing
                                </a>
                                <button onClick={this.validate}>SUBMIT NUMBER</button>
                            </column>
                        </row>
                    </dialog-content>
                </sms-modal>
            );
            // eslint-disable-next-line max-len
            /* eslint-enable react/jsx-closing-bracket-location,react/jsx-closing-tag-location,react/jsx-first-prop-new-line */
        } else if (view && view == 2) {
            return (
                <sms-modal>
                    <dialog-header>
                        <button onClick={close}>×</button>
                        <h1 className='fancy-title has-text-center'>
                            Just One More Step
                        </h1>
                        <p className='has-text-center has-margin-0-bottom'>
                            We just sent you a text! To claim your<br />50% discount, reply <b>Y</b> on your phone
                        </p>
                    </dialog-header>
                    <dialog-content>
                        <p className='has-text-center'>
                            <a
                                href='#'
                                onClick={(e) => {this.trackClick(
                                    'Modal_SMS:Back'
                                ); this.close(e);}}
                            >
                                Back to what I was doing
                            </a>
                        </p>
                    </dialog-content>
                </sms-modal>
            );
        }

        return null;
    }

    setTelephone = (value) => {
        this.setState({
            telephone: {
                value: value,
                error: null,
            },
        });
    }

    setAgree = (e) => {
        this.setState({
            agree: {
                checked: e.target.checked,
                error: null,
            },
        });
    }

    validate = (e) => {
        e.preventDefault();

        let state = { error: false };

        if (!this.state.telephone.value) {
            state.telephone = {
                value: this.state.telephone.value,
                error: 'Please provide your telephone number',
            };

            state.error = true;
        } else if (!this.state.telephone.value.match(/1[0-9]{10}/)) {
            state.telephone = {
                value: this.state.telephone.value,
                error: 'Please enter a valid US phone number',
            };

            state.error = true;
        } else {
            state.telephone = {
                value: this.state.telephone.value,
                error: null,
            };
        }

        if (!this.state.agree.checked) {
            state.agree = {
                checked: this.state.agree.checked,
                error: 'Please agree before continuing',
            };

            state.error = true;
        } else {
            state.agree = {
                checked: this.state.agree.checked,
                error: null,
            };
        }

        if (!state.error) {
            this.props.optIn({
                phone_number: this.state.telephone.value,
                location: 'sms-modal',
            });
        }

        this.setState({ ...this.state, ...state });
    }

    renderHeading = () => {
        if (this.props.productPending) {
            return (
                <h1 className='fancy-title has-text-center'>
                    Sign Up for Cosmic Texts and <br className='is-hidden-mobile' />Get 50% OFF Your Next Reading!
                </h1>
            );
        }

        return (
            <h1 className='fancy-title has-text-center'>
                Sign Up for Cosmic Texts and <br className='is-hidden-mobile' />Get 50% OFF Any Reading!
            </h1>
        );
    }

    renderPhoneError = () => {
        if (this.state.telephone.error) {
            return (<p className='is-error'>{this.state.telephone.error}</p>);
        }

        return null;
    }

    renderAgreeError = () => {
        if (this.state.agree.error) {
            return (<p className='is-error'>{this.state.agree.error}</p>);
        }

        return null;
    }

    renderOptInError = () => {
        if (this.props.optInError) {
            return (<p className='is-error-multiline'>{this.props.optInError}</p>);
        }

        return null;
    }

    close = (e) => {
        e.preventDefault();
        this.props.close();
    }

    trackClick = (info) => {
        this.props.trackEvent(['Click', info]);
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    view: state.user && state.user.sms && state.user.sms.view,
    optInError: state.user && state.user.sms && state.user.sms.error,
    productPending: state.product && state.product.pending,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    optIn: (payload) => dispatch({ type: 'sms:opt-in', payload: payload }),
    routeTo: (route) => dispatch({ type: 'route:to', payload: route }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
